import React from "react";
import { groupType } from "../../util/Types/GroupType";

type GroupSelectProps = {
    onChange(e: any): void;
    groups: Array<groupType>;
    value: string;
};

/**
 * Input commponent for selecting a group from a dropdown Menu
// Allows user to specify on change function
 * @param param0 
 * @returns 
 */
export default function GroupSelect({
    onChange,
    groups,
    value,
}: GroupSelectProps) {
    const groupsSorted = [...groups].sort((a: groupType, b: groupType) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
        }
        return 0;
    });
    return (
        <select
            name="groupId"
            className="w-40  p-1  border-2 focus:outline-none focus:border-teal-200"
            defaultValue={value}
            onChange={onChange}
            title="select Cow Groups"
        >
            <option value=""></option>

            {groupsSorted.map((group: groupType) => {
                return (
                    <option value={group.id} key={group.id}>
                        {group.name}
                    </option>
                );
            })}
        </select>
    );
}
