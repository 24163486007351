import { useState } from "react";
import { Button } from "semantic-ui-react";
import { gql, useMutation } from "@apollo/client";

import { medType } from "../../util/Types/MedType";

type MnaageMedsTabProps = {
    meds: any;
    addMedToState(data: medType): void;
    updateMedInState(data: medType): void;
};
export default function ManageMedsTab({
    meds,
    addMedToState,
    updateMedInState,
}: MnaageMedsTabProps) {
    // const { addMedTo, updateMedContext } = useContext(WorkingContext);

    const [addFlag, setAddFlag] = useState(false);
    // const [selected, setSelected] = useState({ set: false });
    const [values, setValues] = useState<any>({
        medId: "",
        medName: "",
        withPeriod: 0,
        directions: "",
        note: "",
    });
    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };
    // console.log(meds);

    const RenderMedOptions = () => {
        let arr: any = [];
        if (!meds) {
            return null;
        }
        meds.forEach((med: any) => {
            arr.push(
                <option value={med.id} key={med.id}>
                    {med.medName}
                </option>
            );
        });
        return arr;
    };

    const setStartValues = (medId: any) => {
        console.log(medId);
        setAddFlag(false);
        if (medId === "none") {
            setValues({
                ...values,
                medId: medId,
                medName: "",
                directions: "",
                note: "",
                withPeriod: 0,
            });
            return null;
        }
        meds.forEach((med: any) => {
            // console.log(med);
            if (med.id === medId) {
                setValues({
                    ...values,
                    medId: medId,
                    medName: med.medName,
                    directions: med.directions,
                    note: med.note,
                    withPeriod: med.withdrawalPeriod,
                });
            }
        });
    };

    const CREATEMEDQUERY = gql`
        mutation {
            createMed(CreateMedInput: {medName: "${
                values.medName
            }", withPeriod: ${parseInt(values.withPeriod) || 0}, directions: "${
        values.directions
    }",note: "${values.note}"}) {
                id
                medName
                withdrawalPeriod
                directions
                note
            }
        }
    `;

    const UPDATEMEDQUERY = gql`
    mutation{
  updateMed(medId: "${values.medId}", medName: "${
        values.medName
    }", withPeriod: ${parseInt(values.withPeriod) || 0}, directions: "${
        values.directions
    }",note: "${values.note}"){
    id
    medName
    withdrawalPeriod
    directions
    note
  }
}
`;
    const [createMed] = useMutation(CREATEMEDQUERY, {
        onError: (e) => console.log(e),
        onCompleted: (data) => {
            console.log(data);
            addMedToState(data.createMed);
            console.log(values);
        },
    });
    // TODO: getting weird error but update seems to work
    const [updateMed] = useMutation(UPDATEMEDQUERY, {
        onError: (e) => console.log(e),
        onCompleted: (data) => {
            console.log(data);
            updateMedInState(values);
            console.log(values);
        },
    });

    return (
        <div className="p-3 bg-gray-100 rounded">
            <div className="rounded p-3 text-center bg-gray-100 ">
                <select
                    name="medId"
                    className="w-1/4 rounded border-2  p-2 text-black focus:outline-none focus:border-teal-200"
                    onChange={(e) => {
                        // onChange(e);
                        setStartValues(e.target.value);
                    }}
                    value={addFlag ? "none" : values.medId}
                >
                    <option value="none">
                        {addFlag ? "Add Med" : "select med"}
                    </option>
                    <RenderMedOptions />
                </select>
                <Button
                    icon="plus"
                    color="green"
                    className="ml-2"
                    onClick={() => {
                        setAddFlag(true);
                        setValues({
                            ...values,
                            medId: "none",
                            medName: "",
                            directions: "",
                            note: "",
                            withPeriod: 0,
                        });
                    }}
                />
                <div className="rounded flex flex-col items-center justify-evenly">
                    <input
                        type="text"
                        placeholder="Name"
                        className="w-34 m-1 p-2 border-2 rounded focus:outline-none focus:border-teal-200 text-black"
                        name="medName"
                        value={values.medName}
                        onChange={onChange}
                    />
                    <input
                        type="number"
                        placeholder="With Period"
                        className="w-34 m-1 p-2 border-2 rounded focus:outline-none focus:border-teal-200 text-black"
                        name="withPeriod"
                        value={values.withPeriod}
                        onChange={onChange}
                    />
                    <textarea
                        id=""
                        cols={25}
                        rows={5}
                        className="w-34 m-1 border-2 rounded focus:outline-none focus:border-teal-200 text-black p-1"
                        name="directions"
                        value={values.directions}
                        onChange={onChange}
                    >
                        Enter Directions
                    </textarea>
                    <input
                        type="text"
                        placeholder="Notes"
                        className="w-34 m-1 p-2 border-2 rounded focus:outline-none focus:border-teal-200 text-black"
                        name="note"
                        value={values.note}
                        onChange={onChange}
                    />
                    <Button
                        color="green"
                        content="Confirm changes"
                        onClick={() => {
                            if (addFlag) {
                                createMed();
                            } else if (!addFlag && values.medId !== "none") {
                                updateMed();
                            } else {
                                console.log("Not MAdE YeT");
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
