import group from "./groupClass";
import { client } from "../ApolloProvider"
import { gql } from '@apollo/client'

export default class groupCollection {
    constructor(groupDataArr) {
        this.groups = groupDataArr.map((groupData) => new group(groupData))
    }
    getGroups() {
        return this.groups
    }
    addGroup(groupName) {
        const CREATEGROUPQUERY = gql`
        mutation{
     createGroup(name: "${groupName}"){
     id
     name
    }
     }
     `;

        client.mutate({ mutation: CREATEGROUPQUERY }).then((data) => {
            console.log(data)
            this.groups.push(new group(data.data.createGroup))

        }).catch((err) => {
            console.log(err)
        })
    }

    removeGroup(groupId) {
        const DELETEGROUPQUERY = gql`
        mutation{
          deleteGroup(groupId: "${groupId}")
        }
        `;

        client.mutate({ mutation: DELETEGROUPQUERY }).then((data) => {
            console.log(data)
            this.groups = this.groups.filter(group => group.getId() !== groupId)

        }).catch((err) => {
            console.log(err)
        })
    }
    updateGroup(groupId, groupName) {
        const UPDATEGROUPQUERY = gql`
mutation{
  updateGroup(groupId: "${groupId}", name: "${groupName}"){
    id
    name
  }
}
`;
        const group = this.groups.find(group => group.id === groupId)

        client.mutate({ mutation: UPDATEGROUPQUERY }).then((data) => {
            console.log(data)
            this.groups = this.groups.filter(group => group.getId() !== groupId)

        }).catch((err) => {
            console.log(err)
        })
        group.update(groupName)

    }
    archiveGroup(groupId) {
        const ARCHIVEGROUPQUERY = gql`
        mutation{
            archiveGroup(groupId: "${groupId}"){
              group{
                  _id
                name
              }
              cows{	
                _id
                tagNo
                groups{
                  currentGroupID
                  currentGroupName
                  currentPeriodStart
                }
                avgGrowthLifetime
                avgGrowthLifetime
                weights{
                  id
                  date
                  weight
                  growth
                  avgGrowth
                  notes
                  flags
                }
                origin 
                medicineHistory{
                  id
                  medId
                  date
                  if_withdrawalEnd
                  batchNo
                  note
                }
              }
            }
          }
        `
        client.mutate({ mutation: ARCHIVEGROUPQUERY }).then((data) => {
            console.log(data)
            this.groups = this.groups.filter(group => group.getId() !== groupId)

        }).catch((err) => {
            console.log(err)
        })
    }
}
