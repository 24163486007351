import campRepGroup from "./campRepGroupClass";

export default class campReportData {
    constructor(cowCollectionObj, groupObjArray) {
        this.groups = groupObjArray.map((group) => {
            return new campRepGroup(group, cowCollectionObj)
        })

    }
    getRepData() {
        return this.groups
    }
}