import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MenuBar from "./components/MenuBar";

import { AuthProvider } from "./context/auth";
import Router from "./Router";
// TODO: remove dependency on semantic ui
function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                {/* <WorkingContextProvider> */}
                <MenuBar />
                {/*! Spacer to make pages lign up */}
                <div className="h-14 sm:h-16"></div>
                <Router />
                {/* /* </WorkingContextProvider> */}
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
