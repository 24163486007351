//! Library imports
import React, { useState } from "react";

//! component and helper imports
import ConfirmDelete from "../../components/ConfirmDelete";
import feedLogClass from "../../util/feedLogs";
import GroupSelect from "../../components/InputComponents/GroupSelect";

//! Type imports
import { FeedLogType } from "../../util/Types/FeedLogType";
import { groupType } from "../../util/Types/GroupType";

type feedCardProps = {
    Groups?: Array<groupType>;
    feedLog: FeedLogType;
    feedLogHolder?: feedLogClass;
    UpdateFeedLogUiHandler(log: FeedLogType): void;
    deleteFeedLogUiHandler(id: String): void;
};
export default function FeedCard({
    feedLog,
    feedLogHolder,
    Groups,
    deleteFeedLogUiHandler,
    UpdateFeedLogUiHandler,
}: feedCardProps) {
    const [values, setValues] = useState({
        flId: feedLog.id,
        date: feedLog.date,
        groupId: feedLog.group.id ? feedLog.group.id : "unassigned",
        feedType: feedLog.feedType,
        mass: feedLog.mass,
        note: feedLog.note ? feedLog.note : "",
        changed: false,
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
            changed: true,
        });
    };

    if (!feedLogHolder || !Groups) {
        return <>Error</>;
    }
    return (
        <div className="w-full p-2">
            <div className="w-full text-black flex flex-col md:flex-row bg-gray-100 rounded mr-1 p-3 items-center justify-evenly ">
                <input
                    name="date"
                    value={values.date.slice(0, 10)}
                    type="date"
                    className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                    onChange={onChange}
                />
                <div className="flex flex-row">
                    <input
                        name="mass"
                        value={values.mass}
                        className="p-2 w-24 rounded border-2  focus:outline-none focus:border-teal-200"
                        type="number"
                        placeholder="mass"
                        onChange={onChange}
                    />
                    <input
                        name="feedType"
                        value={values.feedType}
                        className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                        type="text"
                        placeholder="Feed Type"
                        onChange={onChange}
                    />
                </div>
                <GroupSelect
                    onChange={(e) => {
                        setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                            changed: true,
                        });
                    }}
                    groups={Groups}
                    value={values.groupId}
                />

                <input
                    name="note"
                    value={values.note}
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="text"
                    placeholder="note"
                    onChange={onChange}
                />
                <div className="flex flex-row">
                    <ConfirmDelete
                        deleteFunc={() => {
                            feedLogHolder.deleteFeedLog(
                                values.flId,
                                deleteFeedLogUiHandler
                            );
                        }}
                    />
                    <button
                        className={`bg-gray-400  hover:bg-gray-300 ${
                            values.changed
                                ? "bg-teal-400  hover:bg-teal-600"
                                : ""
                        } p-3 rounded ml-2`}
                        onClick={() => {
                            if (values.changed) {
                                feedLogHolder.updateFeedLog(
                                    values.flId,
                                    values.date,
                                    values.groupId,
                                    values.feedType,
                                    values.mass,
                                    values.note,
                                    UpdateFeedLogUiHandler
                                );
                            }
                        }}
                    >
                        update
                    </button>
                </div>
            </div>
        </div>
    );
}
