// package imports
import React, { useState } from 'react'

import { Doughnut } from 'react-chartjs-2';
import Please from 'pleasejs';
import { gql, useQuery } from '@apollo/client';

import cowCollection from '../../util/cowCollectionClass';
import groupCollection from '../../util/groupCollectionClass';


export default function WeightDoughnut() {
    const [doughnutState, setDoughnutState] = useState({ cows: null, groups: null })

    const { error } = useQuery(GET_CONTEXT, {
        onCompleted: (data) => {
            setDoughnutState({ cows: new cowCollection(data.getAllCows), groups: new groupCollection(data.getGroups) })
            console.log(data.getAllCows)
            console.log(data.getGroups)
            console.log("Reached")
        }
    });
    if (error) return <>ERROR{console.log(error.message)}</>

    if (!doughnutState.cows || !doughnutState.groups) {
        return (
            <div className="flex justify-center items-center w-full text-4xl">
                <p>loading</p>
            </div>
        );
    }

    const cowState = doughnutState.cows;
    const groupState = doughnutState.groups;


    const dataPrep = groupState.getGroups().map((group) => {
        const totalweight = cowState.getGroupTotalWeight(group.id)
        return { ...group, tw: totalweight }
    })

    let colors = Please.make_color({
        saturation: 1.0,
        value: 1.0,
        colors_returned: dataPrep.length, format: 'hex'
    })

    let labels = [];
    let data = [];

    dataPrep.forEach((group) => {
        labels.push(group.name)
        data.push(group.tw)
    })
    const doughnut = {
        labels: labels,
        datasets: [
            {
                label: 'weightdist',
                backgroundColor: colors,

                data: data
            }
        ]
    }

    return (

        <Doughnut
            data={doughnut}
            height={800}
            width={500}
            options={{
                responsive: true,

                title: {
                    display: true,
                    text: 'Weight by camp',
                    fontSize: 20
                },
                legend: {
                    fullsize: true,
                    display: true,
                    position: 'top'
                }
            }}
        />

    )
}
const GET_CONTEXT = gql`
{
getAllCows{
      id
  tagNo
  origin
  groups{
    currentGroupID
    currentGroupName
    history{
      id
      groupId
      periodStart
      periodEnd
    }
  }
  weights{
    id
    date
    weight
    notes
  }
  medicineHistory{
      id
      medId
      date
      if_withdrawalEnd
      batchNo
      note
    }
  }
  getGroups{
    id
    name
  }
}
`