import React, { useState } from "react";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import { RecipeItemType, RecipeType } from "../../../util/Types/RecipeType";
import ProcessInput from "./ProcessInput";
import AddRecipeItemCard from "./RecipeItems/AddRecipeItemCard";
import RecipeItemCard from "./RecipeItems/RecipeItemCard";
// import RecipeItemsView from "./RecipeItems/RecipeItemsView";
type PageProps = {
    recipe: RecipeType;
    endViewIngCallBack: () => void;
};
type PageState = {
    recipe: RecipeType;
};
export default function SingleRecipeView({
    recipe,
    endViewIngCallBack,
}: PageProps) {
    const [pageState, setPageState] = useState<PageState>({
        recipe: recipe,
    });
    const totalPercentage = pageState.recipe.items
        .map((ri) => ri.percentage)
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b), 0);

    return (
        <div className="p-3 bg-gray-300 flex flex-col justify-evenly items-center">
            <div className="w-full flex justify-between items-center ">
                <ButtonTeal
                    content={"Back"}
                    onClick={function (e: any): void {
                        endViewIngCallBack();
                    }}
                    classNameAdd={"inline flex-start"}
                />
                <h3 className=" m-0 p-0">{recipe.recipeName}</h3>
            </div>
            <div className="w-full flex justify-center items-center ">
                <div
                    className={`p-3 m-2 rounded ${
                        totalPercentage >= 99 && totalPercentage <= 101
                            ? "bg-green-400"
                            : "bg-red-400"
                    }`}
                >
                    total percentage: {totalPercentage}%
                </div>
                <ProcessInput recipeId={pageState.recipe.id} />
            </div>

            <div className="w-full ">
                <AddRecipeItemCard
                    addRiCallback={(ri: RecipeItemType): void => {
                        const riArr = [...pageState.recipe.items];
                        riArr.push(ri);
                        setPageState({
                            ...pageState,
                            recipe: {
                                ...recipe,
                                items: riArr,
                            },
                        });
                    }}
                    recipeId={pageState.recipe.id}
                />
                {pageState.recipe.items.map((item) => {
                    return (
                        <RecipeItemCard
                            recipeItem={item}
                            key={item.id}
                            updateRecipeItemCallback={(
                                ri: RecipeItemType
                            ): void => {
                                setPageState({
                                    ...pageState,
                                    recipe: {
                                        ...recipe,
                                        items: pageState.recipe.items.map(
                                            (item) => {
                                                if (item.id === ri.id) {
                                                    return ri;
                                                }
                                                return item;
                                            }
                                        ),
                                    },
                                });
                            }}
                            deleteCallBack={(id) => {
                                const items = [
                                    ...pageState.recipe.items,
                                ].filter((ri) => ri.id !== id);

                                setPageState({
                                    ...pageState,
                                    recipe: {
                                        ...recipe,
                                        items: items,
                                    },
                                });
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
