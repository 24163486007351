//! package imports
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";

//! context imports

//! component and helper imports
import FeedCard from "./FeedCard";
import feedLogClass from "../../util/feedLogs";
import AddFeedForm from "./AddFeedForm";
import PaginatedListView from "../../components/Pagination/PaginatedListView";
import {
    pageUp,
    pageDown,
    makePageArray,
} from "../../util/helpers/paginationHelpers";
import Loading from "../../components/Loading";

//! Type imports
import { groupType } from "../../util/Types/GroupType";
import { FeedLogType } from "../../util/Types/FeedLogType";

type FeedPageState = {
    feedLogs?: feedLogClass;
    groups?: Array<groupType>;
    paginationArr?: Array<Array<any>>;
    currentPage: number;
    fetched: boolean;
};

export default function Feed() {
    const [PageState, setPageState] = useState<FeedPageState>({
        feedLogs: undefined,
        groups: undefined,
        fetched: false,
        paginationArr: undefined,
        currentPage: 0,
    });

    const { error } = useQuery(GET_FEEDS_AND_GROUPS_QUERY, {
        onCompleted: (data) => {
            // console.log(data.getFeedLogs);
            // console.log(data.getGroups);
            const feedLogHolder = new feedLogClass(data.getFeedLogs);
            setPageState({
                feedLogs: feedLogHolder,
                groups: data.getGroups,
                fetched: true,
                paginationArr: makePageArray(feedLogHolder.getLogs(), 20),
                currentPage: 0,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;

    if (!PageState.fetched) {
        return <Loading />;
    }

    if (
        PageState.feedLogs === undefined ||
        PageState.groups === undefined ||
        PageState.paginationArr === undefined
    )
        return <>error</>;

    // set up array for pagination
    let DisplayItems = PageState.paginationArr[PageState.currentPage].map(
        (log) => {
            return (
                <FeedCard
                    feedLog={log}
                    feedLogHolder={PageState.feedLogs}
                    Groups={PageState.groups}
                    key={log.id}
                    UpdateFeedLogUiHandler={function (log: FeedLogType): void {
                        if (!PageState.feedLogs) {
                            return;
                        }
                        const feedLogHolder = new feedLogClass(
                            PageState.feedLogs.getLogs().map((oldLog) => {
                                return oldLog.id === log.id ? log : oldLog;
                            })
                        );
                        setPageState({
                            ...PageState,
                            feedLogs: feedLogHolder,
                            paginationArr: makePageArray(
                                feedLogHolder.getLogs(),
                                20
                            ),
                        });
                    }}
                    deleteFeedLogUiHandler={function (id: String): void {
                        if (!PageState.feedLogs) {
                            return;
                        }
                        const feedLogHolder = new feedLogClass(
                            PageState.feedLogs
                                .getLogs()
                                .filter((log) => log.id !== id)
                        );
                        setPageState({
                            ...PageState,
                            feedLogs: feedLogHolder,
                            paginationArr: makePageArray(
                                feedLogHolder.getLogs(),
                                20
                            ),
                        });
                    }}
                />
            );
        }
    );

    return (
        <div className="container w-full h-full mt-4">
            <AddFeedForm
                Groups={PageState.groups}
                AddFeedUiHandler={(logAdded: FeedLogType): void => {
                    if (!PageState.feedLogs) {
                        return;
                    }
                    const feedLogHolder = new feedLogClass([
                        logAdded,
                        ...PageState.feedLogs.getLogs(),
                    ]);
                    setPageState({
                        ...PageState,
                        feedLogs: feedLogHolder,
                        paginationArr: makePageArray(
                            feedLogHolder.getLogs(),
                            20
                        ),
                    });
                }}
            />
            <div className="w-full h-vh70 bg-white">
                <PaginatedListView
                    DisplayItems={DisplayItems}
                    pageUpHandler={() => {
                        const pageNo = pageUp(
                            PageState.currentPage,
                            PageState.paginationArr
                        );
                        setPageState({ ...PageState, currentPage: pageNo });
                    }}
                    pageDownHandler={() => {
                        const pageNo = pageDown(PageState.currentPage);
                        setPageState({ ...PageState, currentPage: pageNo });
                    }}
                    getPageCount={function (): number {
                        // console.log(PageState.paginationArr);
                        return PageState.paginationArr
                            ? PageState.paginationArr.length
                            : 0;
                    }}
                    getCurrentPage={function (): number {
                        return PageState.currentPage + 1;
                    }}
                />
            </div>
        </div>
    );
}
const GET_FEEDS_AND_GROUPS_QUERY = gql`
    {
        getFeedLogs {
            id
            date
            mass
            group {
                id
                name
            }
            feedType
            note
        }
        getGroups {
            id
            name
        }
    }
`;
