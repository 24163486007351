import React, { ReactElement, useState } from "react";

type PageState = {
    openTab: string;
    openTabElement: ReactElement;
};
type TabNavigateInput = {
    startTab: TabInput;
    tabArray: TabInput[];
};
type TabInput = {
    tabIndex: string;
    buttonText: string;
    element: ReactElement;
};
export default function TabNavigate({ startTab, tabArray }: TabNavigateInput) {
    const [pageState, setPageState] = useState<PageState>({
        openTab: startTab.tabIndex,
        openTabElement: startTab.element,
    });

    const getButttonCssString = (tabName: string) => {
        return `${
            pageState.openTab === tabName ? "bg-teal-400" : "bg-gray-400"
        }  hover:bg-teal-600 p-3 rounded mr-1`;
    };

    return (
        <div className="mt-3">
            <div
                className={`flex bg-gray-300  w-full rounded ${
                    tabArray.length > 3 ? "overflow-x-scroll" : ""
                }`}
            >
                {tabArray.map((pageInfo) => {
                    return (
                        <button
                            className={getButttonCssString(pageInfo.tabIndex)}
                            key={pageInfo.tabIndex}
                            onClick={() => {
                                setPageState({
                                    ...pageState,
                                    openTab: pageInfo.tabIndex,
                                    openTabElement: pageInfo.element,
                                });
                            }}
                        >
                            {pageInfo.buttonText}
                        </button>
                    );
                })}
            </div>
            <div className="bg-gray-100 rounded mt-0 text-black">
                {pageState.openTabElement}
            </div>
        </div>
    );
}
