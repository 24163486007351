import React from "react";

import AniEditModal from "./EditModal/AniEditModal";
import ConfirmDelete from "../../components/ConfirmDelete";
import { groupType } from "../../util/Types/GroupType";
import indivCow from "../../util/indivCowClass";

type AniCardProps = {
    groups: Array<groupType>;
    cow: indivCow;
    uiDeleteCowHandler(cowId: string): void;
};
export default function AniCard({
    groups,
    cow,
    uiDeleteCowHandler,
}: AniCardProps) {
    const group = groups.find((group) => group.id === cow.getCGId());
    const cowWeights = cow.weights;
    return (
        <div className="bg-cnfmgrn flex flex-row text-black rounded mt-2">
            <div className="w-full flex flex-row bg-gray-100 rounded mr-1 p-3 items-center justify-evenly ">
                <div className="p-0 mx-1 h-full  rounded text-center bg-gray-100 flex flex-row items-center justify-between">
                    <div className="bg-gray-300 p-2 rounded">
                        <h2 className="p-0 m-0">Tag No:</h2>
                        <h3 className="p-0 m-0">{cow.getTag()}</h3>
                    </div>
                    <div className="ml-2 hidden md:block bg-gray-300 p-2 rounded">
                        <h2 className="m-0 p-0"> Origin:</h2>
                        <h3 className="p-0 m-0">{cow.getOrigin()}</h3>
                    </div>
                    <div className="ml-2 bg-gray-300 p-2 hidden sm:block rounded">
                        <h2 className="m-0 p-0"> Group:</h2>

                        <h3 className="p-0 m-0">
                            {group ? group.name : "N/A"}
                        </h3>
                    </div>
                    <div className="ml-2 bg-gray-300 p-2 rounded">
                        <h2 className="m-0 p-0"> Last Weight:</h2>
                        <h3 className="m-0">{cow.getLW()} KG</h3>
                        <h3 className="m-0">
                            {cowWeights.length > 0
                                ? cowWeights[cowWeights.length - 1].date.slice(
                                      0,
                                      10
                                  )
                                : "N/A"}
                        </h3>
                    </div>
                    <div className="ml-2 hidden sm:block bg-gray-300 p-2 rounded">
                        <h2 className="m-0 p-0"> Avg Growth:</h2>

                        <h3 className="m-0 p-0">{cow.getLifetimeAvg()}</h3>
                    </div>
                </div>

                <div className="flex justify-center items-center">
                    <ConfirmDelete
                        deleteFunc={() => {
                            cow.delete();
                            uiDeleteCowHandler(cow.id);
                        }}
                    />
                    <AniEditModal cow={cow} groups={groups} />
                </div>
            </div>
        </div>
    );
}
