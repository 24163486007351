import jsPDF from "jspdf";
import "jspdf-autotable";

export default function repGenerator(repClass) {

    const doc = new jsPDF({
        orientation: "landscape",
    });
    let first = true;
    repClass.forEach((group) => {
        const sessions = group.getSessions()
        if (!first) {
            doc.addPage();
        }
        doc.text(group.getName(), 15, 10);
        const tableColumn = ["Date", "TotalWeight", "AvgWeight", "Projected Feed", "Avg Growth", "Weight Gain", "Weighed"];
        const tableRows = [];
        sessions.forEach((session) => {
            const dataArr = [
                session.date.slice(0, 10),
                session.totalWeight,
                session.avgWeight.toFixed(2),
                (session.totalWeight * 0.035).toFixed(1),
                !session.isFirstSession
                    ? session.avgGrowthRate.toFixed(2)
                    : "N/A",
                !session.isFirstSession ? session.totalGrowth : "N/A",
                session.countOfWeighed]

            tableRows.push(dataArr)
        })
        // doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.autoTable({ head: [tableColumn], body: [...tableRows], startY: 20 });
        // console.log(tableColumn)
        first = false
    })
    // doc.text("Hello world!", 2, 5);
    // doc.addPage();

    doc.save("CampReport.pdf");
}