import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { client } from "../../../../ApolloProvider";
import ButtonTeal from "../../../../components/InputComponents/ButtonTeal";
import Loading from "../../../../components/Loading";
import { RecipeItemType } from "../../../../util/Types/RecipeType";
import { StockItem } from "../../../../util/Types/StockItemType";
import {
    createRiInput,
    validateCreateRiInput,
} from "../../../../util/validators/validateRecipeItem";
import { FETCH_ALL_STOCKITEMS_QUERY } from "../../StockItems/StockItemPageGQLQueries";
import { GET_CREATE_RECIPE_ITEM_QUERY } from "../RecipePageGQLQueries";

type Props = {
    addRiCallback: (ri: RecipeItemType) => void;
    recipeId: string;
};

type PageState = {
    percentage: number | string;
    recipeID?: string;
    stockItemId?: number | string;
    fetched: boolean;
    stockItems: StockItem[];
};
export default function AddRecipeItemCard({ addRiCallback, recipeId }: Props) {
    const [pageState, setPageState] = useState<PageState>({
        percentage: 0,
        recipeID: recipeId,

        fetched: false,
        stockItems: [],
    });

    const { error } = useQuery(FETCH_ALL_STOCKITEMS_QUERY, {
        onCompleted: (data) => {
            console.log(data.getStockItems);

            setPageState({
                ...pageState,

                fetched: true,
                stockItems: data.getStockItems,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    return (
        <div className="w-full p-3 bg-gray-100 rounded mb-2  flex justify-evenly items-center">
            <input
                type="number"
                className="p-3 m-2 rounded  w-1/4"
                max="100"
                min="0"
                placeholder="Percentage"
                value={pageState.percentage}
                onChange={(e: any) => {
                    setPageState({ ...pageState, percentage: e.target.value });
                }}
            />
            <select
                name="stockitem"
                className="p-3 m-2 rounded bg-white w-1/4"
                onChange={(e: any) => {
                    setPageState({ ...pageState, stockItemId: e.target.value });
                }}
            >
                <option value={""}>-----</option>
                {pageState.stockItems.map((si) => {
                    return (
                        <option value={si.id} key={si.id}>
                            {si.stockItemName}
                        </option>
                    );
                })}
            </select>
            <ButtonTeal
                content={"Add"}
                onClick={function (): void {
                    const createInput: createRiInput = {
                        percentage: pageState.percentage,
                        recipeID: pageState.recipeID,
                        stockItemId: pageState.stockItemId,
                    };

                    const validatorResp = validateCreateRiInput(createInput);
                    if (!validatorResp.valid) {
                        if (validatorResp.errors) {
                            let outStr = "";
                            validatorResp.errors.forEach((error) => {
                                outStr += error + "\n";
                            });

                            alert(outStr);
                        }
                        return;
                    }
                    client
                        .mutate({
                            mutation: GET_CREATE_RECIPE_ITEM_QUERY(createInput),
                        })
                        .then((data) => {
                            console.log(data);
                            alert("Success");
                            addRiCallback(data.data.createRecipeItem);
                        });
                }}
                classNameAdd={""}
            />
        </div>
    );
}
