import React, { useContext } from "react";

import { AuthContext } from "../../context/auth";
import WeightDoughnut from "./WeightDoughnut";
// import BarGrowth from "./subComponents/home/BarGrowth";
// import LineWeight from "./subComponents/home/LineWeight";

export default function Home() {
    const context = useContext(AuthContext);

    if (!context.user) {
        return (
            <div className="notice">
                <h2>Cow Tracker</h2>
                <p>
                    Please <a href="/login">Log in</a>
                </p>
            </div>
        );
    }

    return (
        <div className=" container mt-4 rounded text-black flex flex-col bg-gray-100 px-3 py-2 xl:w-10/12 lg:px-36 flex flex-col items-center justify-evenly">
            <h1 className="mb-auto">DASHBOARD</h1>
            <div className="relative w-full text-xs p-10">
                <WeightDoughnut />
                {/* <BarGrowth /> */}
            </div>
        </div>
    );
}
