import React, { useState } from "react";

// import { isBefore, isAfter } from "../../../util/helpers/dateHelper";

import FeedCard from "./FeedCard";
import groupCollectionClass from "../../util/groupCollectionClass";
import feedRepGenerator from "../../util/printGenerators/feedRepGenerator";
import { groupType } from "../../util/Types/GroupType";
import { FeedLogType } from "../../util/Types/FeedLogType";

type FeedRepProps = {
    groups: Array<groupType>;
    feedLogs: Array<FeedLogType>;
};

export default function FeedRep({ groups, feedLogs }: FeedRepProps) {
    const [settingsDisplay, setSettingsDisplay] = useState(false);
    const [logState, setLogState] = useState(feedLogs);
    const [values, setValues] = useState({
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
    });
    const uniqueGroups = new Set(logState.map((log) => log.group.id));

    const updateLogState = () => {
        setLogState(
            feedLogs.filter(
                (log) =>
                    new Date(log.date.slice(0, 10)) <=
                        new Date(values.dateTo.slice(0, 10)) &&
                    new Date(log.date.slice(0, 10)) >=
                        new Date(values.dateFrom.slice(0, 10))
            )
        );
    };
    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };
    return (
        <div className="bg-gray-100 mt-6 rounded text-black p-5 ">
            <div className="flex flex-col">
                <h1>Feed Report</h1>
                <div className="flex flex-col">
                    <button
                        className="bg-teal-400 my-2 hover:bg-teal-600 p-3 w-min rounded h-11"
                        onClick={() => {
                            setSettingsDisplay(!settingsDisplay);
                        }}
                    >
                        Settings
                    </button>
                    {settingsDisplay ? (
                        <div className="bg-gray-300 flex  justify-center flex-col sm:flex-row p-4 items-center my-2 ">
                            <h3 className="m-0 p-0 mr-2">Date From</h3>
                            <input
                                className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                                type="date"
                                name="dateFrom"
                                value={values.dateFrom.slice(0, 10)}
                                onChange={onChange}
                            />
                            <h3 className="p-0 m-2">Date To</h3>
                            <input
                                className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                                type="date"
                                name="dateTo"
                                value={values.dateTo.slice(0, 10)}
                                onChange={onChange}
                            />
                            <button
                                className="bg-teal-400 m-2 hover:bg-teal-600 p-3 rounded"
                                onClick={() => {
                                    updateLogState();
                                }}
                            >
                                Update
                            </button>
                        </div>
                    ) : null}
                    <button
                        className="bg-teal-400 hover:bg-teal-600 p-3 rounded w-min"
                        onClick={() => {
                            feedRepGenerator(
                                logState,
                                [values.dateFrom, values.dateTo],
                                new groupCollectionClass(groups),
                                uniqueGroups
                            );
                        }}
                    >
                        Print
                    </button>
                </div>
            </div>

            <div className="w-full p-3 bg-gray-300 flex flex-col sm:flex-row  items-center justify-evenly">
                <h1 className="m-0 p-0">
                    Total Feed Fed: [
                    {logState.reduce((total, log) => {
                        // console.log(log.mass);
                        return total + log.mass;
                    }, 0)}
                    ]
                </h1>
            </div>
            <div className="pb-4  h-vh70">
                <div className="overflow-y-scroll h-full">
                    {Array.from(uniqueGroups).map((group) => {
                        const groupObj = groups.find(
                            (groupEl) => groupEl.id === group
                        );
                        // TODO: Check effects of this
                        if (groupObj === undefined) {
                            return null;
                        }
                        return (
                            <FeedCard
                                key={group}
                                group={groupObj}
                                logs={logState.filter(
                                    (log) => log.group.id === group
                                )}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
