import campRepCow from "./campRepCow"

export default class campRepGroup {
    constructor(groupObj, sourceObj) {

        this.dates = sourceObj.getWeighDates(groupObj.id).sort(function (a, b) {
            return (new Date(a) - new Date(b))
        }).map((date) => { return { date: date, include: true } })
        this.group = { ...groupObj }
        this.contains = sourceObj.getByGroup(groupObj.id)
        this.sourceObj = sourceObj
        this.cows = sourceObj.getByGroup(groupObj.id).map(cow => new campRepCow(cow))
    }
    getCount() {
        return this.contains.length;
    }
    getName() {
        return this.group.name
    }

    getSessions() {
        const cows = this.cows.map((cow) => cow.getSessions(this.dates))
        const sessions = this.dates.filter(date => date.include)
            .map(date => ({
                [date.date]: cows.map(cow => cow.filter(weight => weight.date === date.date)[0])
                    .filter(sess => !!sess)
            }))

        const ret = sessions.map((sess) => {
            const session = sess[Object.keys(sess)[0]]
            const tw = session.reduce((tot, weight) => {
                return tot + weight.weight
            }, 0)
            const count = session.length
            const firstCount = session.reduce((tot, weight) => {
                if (weight.firstOrErr) {
                    return tot + 1
                }
                return tot
            }, 0)
            const growth = session.reduce((tot, weight) => {
                if (weight.firstOrErr) {
                    return tot
                }
                return tot + weight.weightGain
            }, 0)
            if (count === firstCount) {
                return {
                    date: Object.keys(sess)[0],
                    totalWeight: tw,
                    avgWeight: tw / count,
                    countOfWeighed: count,
                    firstCount: firstCount,
                    isFirstSession: true,
                    totalGrowth: null,
                    avgGrowthRate: null,
                    daysPassed: null,
                    sessions: sessions
                }
            }
            const daysPassed = session.reduce((tot, weight) => {
                if (weight.firstOrErr) {
                    return tot
                }
                return tot + weight.daysPassed
            }, 0) / (count - firstCount)
            return {
                date: Object.keys(sess)[0],
                totalWeight: tw,
                avgWeight: tw / count,
                countOfWeighed: count,
                firstCount: firstCount,
                isFirstSession: false,
                totalGrowth: growth,
                avgGrowthRate: (growth / (count - firstCount)) / daysPassed,
                daysPassed: daysPassed
            }
        })
        return ret
    }

    getDates() {
        return this.dates
    }

    getTotalWeight() {
        let totalWeight = 0
        this.contains.forEach(cow => totalWeight += cow.getLW() !== "N/A" ? cow.getLW() : 0)
        return totalWeight;
    }
    toggleSession(date) {
        this.sessions = this.sessions.map((session) => {
            if (session.date === date) {
                return {
                    ...session,
                    include:
                        !session.include,
                };
            }
            return { ...session };
        })
        return this.getSessions()

    }
    toggleDate(dateArg) {
        this.dates = this.dates.map((date) => {
            if (date.date === dateArg) {
                return {
                    ...date,
                    include:
                        !date.include,
                };
            }
            return { ...date };
        })
        return this.getDates()

    }
}