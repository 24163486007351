import indivCow from "../../../util/indivCowClass";

const func =
 ( StartData: Array<indivCow>,
    filterValue: String,) :Array<indivCow> =>{
        const filtered = StartData.filter((cow: indivCow) => {
            if (filterValue === "") {
                return true;
            }
            return cow.getCGId() === filterValue;
        });
        return filtered
    
}
export default  func