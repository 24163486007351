import React from "react";
import { client } from "../../../ApolloProvider";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import { RecipeType } from "../../../util/Types/RecipeType";
import { GET_DELETE_RECIPE_QUERY } from "./RecipePageGQLQueries";
type pageProps = {
    recipe: RecipeType;
    viewRecipeCallBack: () => void;
    deleteCallBack: (id: string) => void;
};
export default function RecipeCard({
    recipe,
    viewRecipeCallBack,
    deleteCallBack,
}: pageProps) {
    return (
        <div className="w-full items-center justify-between flex p-3 bg-gray-100 mb-2 pl-6 pr-6">
            {recipe.recipeName}
            <div className="flex items-center">
                <ButtonTeal
                    content={"View"}
                    onClick={function (e: any): void {
                        viewRecipeCallBack();
                    }}
                    classNameAdd={"mr-2"}
                />
                <ConfirmDelete
                    deleteFunc={() => {
                        client
                            .mutate({
                                mutation: GET_DELETE_RECIPE_QUERY(recipe.id),
                            })
                            .then((data) => {
                                console.log(data);
                                deleteCallBack(recipe.id);
                            });
                    }}
                />
            </div>
        </div>
    );
}
