import React from 'react'
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function PrintWeighSheetComponent({ cowState, groupState }) {

    const PrintWeighSheet = () => {
        const doc = new jsPDF({
            orientation: "portrait",
        });

        let first = true;

        groupState.getGroups().forEach((group) => {
            const contains = cowState.getByGroup(group.id);
            let totalWeight = 0;
            contains.forEach((cow) => totalWeight += cow.getLW() !== "N/A" ? cow.getLW() : 0)
            contains.sort((a, b) => {
                const tagA = a.getTag().toUpperCase(); // ignore upper and lowercase
                const tagB = b.getTag().toUpperCase(); // ignore upper and lowercase
                if (tagA < tagB) {
                    return -1;
                }
                if (tagA > tagB) {
                    return 1;
                }

                // names must be equal
                return 0;

            })
            console.log(contains)
            const tableColumn = ["Tag No", "last Date", "lastWeight", "Weight"];

            if (!first) {
                doc.addPage();
            }
            doc.text(group.name, 15, 10);

            const tableRows = [
                [{ content: 'TotalWeight:', styles: { fillColor: 'yellow' } },
                { content: `${totalWeight}`, styles: { fillColor: 'yellow' } }
                    // "last Date",
                    // "lastWeight",
                    // "Weight"],
                ]

            ];
            contains.forEach((cow) => {
                const add = [cow.getTag(), cow.getLWDate(), cow.getLW(), "___________"]


                tableRows.push(add)
            })

            doc.autoTable({ head: [tableColumn], body: [...tableRows], startY: 20 });

            first = false
        })


        doc.save("WeighSheet.pdf");
    }
    return (
        <button className="bg-teal-400 hover:bg-teal-600 p-3 rounded ml-2"
            onClick={() => {
                PrintWeighSheet()
            }}
        >Print Sheet
        </button>
    )
}
