import React, { useState } from "react";
import { Button } from "semantic-ui-react";

// import { gql, useMutation } from "@apollo/client";

type MassCapMedsProps = {
    cowObj: any;
    meds: any;
};
export default function MassCapMeds({ cowObj, meds }: MassCapMedsProps) {
    const cow = cowObj.cow;
    const [values, setValues] = useState({
        date: new Date().toISOString().slice(0, 10),
        medId: "",
        batchNo: "",
        note: "",
    });
    const [saved, setSaved] = useState(false);
    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    if (!cowObj.render) {
        return <> </>;
    }

    return (
        <div className="bg-gray-300 rounded text-black mb-3 w-full ">
            <div className="  flex justify-evenly items-center w-full flex-col sm:flex-row p-3 px-5">
                <div className="flex p-3  mb-2 bg-gray-100">
                    <h3 className="m-0 inline-block">Tag: </h3>
                    <p> {cow.getTag()}</p>
                </div>
                <div className="flex flex-col">
                    <select
                        name="medId"
                        className=" border-2 border-gray-200 rounded p-2 focus:outline-none focus:border-2 focus:border-teal-300"
                        onChange={onChange}
                    >
                        {/* <option value="unassigned">Unassigned</option> */}
                        {meds.map((med: any) => {
                            return (
                                <option key={med.id} value={med.id}>
                                    {med.medName}
                                </option>
                            );
                        })}
                    </select>

                    <input
                        name="date"
                        className="p-1 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                        type="date"
                        value={values.date}
                        onChange={onChange}
                    />
                </div>
                <div className="flex flex-col  m-0 mb-3">
                    <input
                        name="batchNo"
                        className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                        type="text"
                        placeholder="batch"
                        value={values.batchNo}
                        onChange={onChange}
                    />
                    <input
                        type="text "
                        className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                        placeholder="note"
                        value={values.note}
                        name="note"
                        onChange={onChange}
                    />
                </div>

                {saved ? null : (
                    <Button
                        color="green"
                        icon="save"
                        onClick={() => {
                            cow.addMed(
                                values.medId,
                                values.date,
                                values.batchNo,
                                values.note
                            );
                            setSaved(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
