import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";

export default function MassCapWeights({ cowObj, }) {
    const [saved, setSaved] = useState(false);
    const cow = cowObj.cow;
    const weights = cow.getWeights();
    const wl = weights.length;

    // console.log(weights)
    const getAvg = (first, last) => {
        // console.log(last);
        if (first && last && last.weight !== 0 && first.weight !== 0) {
            const Growth = last.weight - first.weight;
            const Time = new Date(last.date) - new Date(first.date);

            const result = Growth / (Time / (1000 * 60 * 60 * 24));
            return result.toFixed(3);
        } else return "N/A";
    };


    const [values, setValues] = useState({
        date: new Date().toISOString().slice(0, 10),
        weight: 0,
        note: "",
        tagNo: cow.getTag()
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    if (!cowObj.render) {
        return <> </>;
    }

    return (
        <div className="bg-gray-300 rounded text-black mb-3 w-full ">
            <div className=" flex justify-evenly items-center w-full flex-col sm:flex-row p-3 px-5">
                <div className="h-full flex flex-row  sm:flex-col md:flex-row ">
                    <div className="flex sm:flex-col">
                        <div className="flex p-3 mr-3 sm:mr-0 mb-2 bg-gray-100">
                            <h3 className="m-0 inline-block">Tag: </h3>
                            {/* <p> {cow.getTag()}</p> */}
                            <input
                                name="tagNo"
                                value={values.tagNo}
                                className="p-2 ml-2 w-24 rounded border-2 bg-white   focus:outline-none focus:border-teal-200"
                                type="text"
                                placeholder="TagNo"
                                onChange={onChange}
                            />
                        </div>
                        <div className="flex p-3  mb-2 bg-gray-100">
                            <h3 className="m-0 inline-block">LW: </h3>
                            <p className="m-0">{cow.getLW()} Kg</p>
                        </div>
                    </div>
                    <div className="flex md:ml-3 items-center  justify-center flex-col">
                        <div className=" p-3 hidden sm:inline-block mb-2 bg-gray-100">
                            <h3 className="m-0 hidden sm:inline-block">
                                lastDate:{" "}
                            </h3>
                            <p className="m-0 hidden sm:inline-block">
                                {cow.getLWDate()}
                            </p>
                        </div>
                        <div className=" p-3 hidden sm:inline-block mb-2 bg-gray-100">
                            <h3 className="m-0  hidden sm:inline-block">
                                last avg:{" "}
                            </h3>
                            <p className="m-0 hidden sm:inline-block">
                                {getAvg(weights[wl - 2], weights[wl - 1])} kg/d
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center h-full">
                    <input
                        name="weight"
                        value={values.weight}
                        className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                        type="number"
                        placeholder="weight"
                        onChange={onChange}
                    />
                    <input
                        name="date"
                        value={values.date}
                        type="date"
                        className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                        onChange={onChange}
                    />
                    <input
                        name="note"
                        value={values.note}
                        className="p-2 w-40 rounded border-2  focus:outline-none bg-white  focus:border-teal-200"
                        type="text"
                        placeholder="Note"
                        onChange={onChange}
                    />
                </div>
                <div className="flex flex-col h-full items-center">
                    <h5 className="m-0 mb-3">
                        {getAvg(weights[wl - 1], values)} kg/d
                    </h5>
                    {saved ? null : (

                        <ButtonTeal
                            content={<FaSave />}
                            onClick={() => {
                                cow.addWeight(
                                    values.date,
                                    values.weight,
                                    values.note
                                );
                                if (values.tagNo !== cow.getTag()) {
                                    cow.updateTag(values.tagNo)
                                }
                                setSaved(true);
                            }}
                        />
                        // <Button
                        //     color="green"
                        //     icon="save"
                        //     onClick={() => {
                        //         cow.addWeight(
                        //             values.date,
                        //             values.weight,
                        //             values.note
                        //         );
                        //         if (values.tagNo !== cow.getTag()) {
                        //             cow.updateTag(values.tagNo)
                        //         }
                        //         setSaved(true);
                        //     }}
                        // />
                    )}
                </div>
            </div>
        </div>
    );
}
