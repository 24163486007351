import ValidatorResponse from "../Types/ValidatorResponseInterface";

export default class simpleValidatorResponse implements ValidatorResponse {
    valid: boolean;
    errors: String[] | undefined;

    constructor(valid: boolean,
        errors?: String[]){
            this.valid = valid
            this.errors = errors
    }
}