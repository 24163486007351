import React from "react";

export default function WeighIn({ session }) {

    return (
        <div>
            <div className=" mt-2 h-full  rounded text-center bg-gray-100 text-black border-2 border-black p-3 overflow-x-scroll">
                <h3 className="p-0 m-0">{session.date.slice(0, 10)}</h3>
                <div className="flex justify-between  items-center">
                    <div className="mr-2 w-24">
                        <h3>Total Weight</h3>
                        <p>[{session.totalWeight}] KG</p>
                        <p>[{session.countOfWeighed}]</p>
                    </div>
                    <div className="mr-2  w-24">
                        <h3>Avg Weight</h3>
                        <p>[{session.avgWeight.toFixed(2)}] KG</p>
                    </div>
                    <div className="mr-2  w-24">
                        <h3>Projected Feed Amt</h3>
                        <p>[{(session.totalWeight * 0.035).toFixed(1)}] KG</p>
                    </div>
                    <div className="mr-2  w-24">
                        <h3>Avg Growth</h3>
                        <p>
                            [
                            {!session.isFirstSession
                                ? session.avgGrowthRate.toFixed(2)
                                : "N/A"}
                            ] KG
                        </p>
                    </div>

                    <div className="mr-2  w-24">
                        <h3>weight Gain</h3>
                        <p>
                            [{!session.isFirstSession ? session.totalGrowth : "N/A"}]
                            KG
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
