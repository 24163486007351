//! Library imports
import React, { useState } from "react";

//! component and helper imports
import ButtonTeal from "../../components/InputComponents/ButtonTeal";
import GroupSelect from "../../components/InputComponents/GroupSelect";
import feedLogClass from "../../util/feedLogs";

//! Type imports
import { groupType } from "../../util/Types/GroupType";
import { FeedLogType } from "../../util/Types/FeedLogType";

type addFormProps = {
    Groups: Array<groupType> | null;
    AddFeedUiHandler(logAdded: FeedLogType): void;
};

export default function AddFeedForm({
    Groups,
    AddFeedUiHandler,
}: addFormProps): JSX.Element {
    const [values, setValues] = useState({
        mass: 0,
        date: new Date().toISOString(),
        groupId: "unassigned",
        feedType: "",
        note: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    if (!Groups) return <></>;

    return (
        <div className=" bg-gray-100 flex flex-col md:flex-row md:justify-center justify-between p-5 text-black items-center rounded ">
            <div>
                <input
                    name="feedType"
                    value={values.feedType}
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="text"
                    placeholder="Feed Type"
                    onChange={onChange}
                />
                <input
                    name="mass"
                    value={values.mass}
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="number"
                    placeholder="mass"
                    onChange={onChange}
                />
            </div>
            <div>
                <GroupSelect
                    onChange={(e) => {
                        setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                        });
                    }}
                    groups={Groups}
                    value={"unassigned"}
                />

                <input
                    name="date"
                    value={values.date.slice(0, 10)}
                    type="date"
                    className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                    onChange={onChange}
                />
            </div>
            <div>
                <input
                    name="note"
                    value={values.note}
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="text"
                    placeholder="note"
                    onChange={onChange}
                />

                <ButtonTeal
                    content="Add"
                    classNameAdd={""}
                    onClick={() => {
                        if (values.groupId === "unassigned") {
                            alert("Group must be assigned");
                            return;
                        }
                        new feedLogClass().addLog(
                            values.date,
                            values.groupId,
                            values.feedType,
                            values.mass,
                            values.note,
                            AddFeedUiHandler
                        );
                    }}
                />
            </div>
        </div>
    );
}
