// package impors
import { useState } from "react";

// component and helper imports
import { FaSave } from "react-icons/fa";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import cowCollection from "../../../util/cowCollectionClass";
import groupCollection from "../../../util/groupCollectionClass";
import GroupSelect from "../../../components/InputComponents/GroupSelect";

type AddSubCompProps = {
    cows: cowCollection;
    groups: groupCollection;
};
export default function AddSubComp({ groups, cows }: AddSubCompProps) {
    const [values, setValues] = useState({
        cowTag: "",
        startWeight: "",
        startDate: new Date().toISOString().slice(0, 10),
        origin: "",
        currentGroupId: "unassigned",
        groupPeriodStart: new Date().toISOString(),
    });

    const [saved, setSaved] = useState(false);

    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        console.log(e.target.name);
    };
    // TODO: add validation
    return (
        <div className="flex flex-col md:flex-row text-black rounded mt-1 p-3 items-center justify-evenly border-2 bg-gray-300">
            <div className="flex">
                <input
                    name="cowTag"
                    value={values.cowTag}
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="text"
                    placeholder="Cow Tag No"
                    onChange={onChange}
                />
                <input
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="text"
                    placeholder="Origin"
                    onChange={onChange}
                    name="origin"
                    value={values.origin}
                />
            </div>
            <div className="flex">
                <input
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    placeholder="Start Weight"
                    type="number"
                    onChange={onChange}
                    name="startWeight"
                    value={values.startWeight}
                />

                <input
                    className="p-2 w-40 rounded border-2  focus:outline-none focus:border-teal-200"
                    type="date"
                    onChange={onChange}
                    name="startDate"
                    value={values.startDate}
                />
            </div>
            <div>
                <GroupSelect
                    onChange={(e) => {
                        setValues({
                            ...values,
                            currentGroupId: e.target.value,
                        });
                    }}
                    groups={groups.getGroups()}
                    value={values.currentGroupId}
                />
            </div>

            {!saved ? (
                <ButtonTeal
                    content={<FaSave />}
                    classNameAdd="m-2"
                    onClick={() => {
                        cows.addCow({
                            cowTag: values.cowTag,
                            startWeight: values.startWeight,
                            startDate: values.startDate,
                            currentGroupID: values.currentGroupId,
                            origin: values.origin,
                            groupPeriodStart: values.groupPeriodStart,
                        });
                        setSaved(true);
                    }}
                />
            ) : null}
        </div>
    );
}
