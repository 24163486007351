// import { difference } from "../helpers/setHelper";
import { isBefore, isAfter, calculateDaysBetween } from "../helpers/dateHelper";
export default class repCow {
    constructor(cowObj) {
        this.sourceObj = cowObj;
        this.weights = cowObj.getWeights()
        this.weighDates = new Set(this.weights.map(weight => weight.date))
        // console.log(this.weighDates)
    }

    // must return
    // weight
    // growth
    // weightgain/growth
    getSessions(datesArg) {
        const datesToReturn = new Set(datesArg.filter(date => date.include).map(date => date.date))
        // const datesMissing = difference(datesToReturn, this.weighDates)
        // console.log([...datesMissing])
        const weights = this.weights.filter(weight => datesToReturn.has(weight.date)).map((weightObj) => {
            let prevWeight;
            this.weights.forEach((weight) => {
                if (!datesToReturn.has(weight.date)) {
                    return
                }
                else if (isBefore(weight.date, weightObj.date)) {
                    if (!prevWeight) {
                        prevWeight = weight
                    } else if (prevWeight && isAfter(weight.date, prevWeight.date)) {
                        prevWeight = weight
                    } else {
                        return
                    }
                }
            })
            // console.log({ prev: prevWeight, curr: weightObj, arr: this.weights })
            if (!prevWeight) {
                return {
                    tagNo: this.sourceObj.getTag(),
                    growthRate: null,
                    weightGain: null,
                    weight: weightObj.weight,
                    date: weightObj.date,
                    daysPassed: null,
                    prevWeight: null,
                    firstOrErr: true
                }
            } else {
                const daysPassed = calculateDaysBetween(weightObj.date, prevWeight.date)
                const weightGain = weightObj.weight - prevWeight.weight
                return {
                    tagNo: this.sourceObj.getTag(),
                    growthRate: weightGain / daysPassed,
                    weightGain: weightGain,
                    weight: weightObj.weight,
                    date: weightObj.date,
                    daysPassed: daysPassed,
                    prevWeight: prevWeight,
                    firstOrErr: false
                }
            }
        })
        // const weightRet = { tagNo: this.sourceObj.getTag(), growth: null, weightGain: null, weight: null }
        return weights
    }
}