import React, { useState } from "react";
import { client } from "../../../../ApolloProvider";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import ButtonTeal from "../../../../components/InputComponents/ButtonTeal";
import { StockMovement } from "../../../../util/Types/StockMovementType";
import ValidatorResponse from "../../../../util/Types/ValidatorResponseInterface";
import { validateUpdateSmInput } from "../../../../util/validators/ValidateStockMovement";
import {
    GET_DELETE_SM_QUERY,
    GET_UPDATE_SM_QUERY,
} from "../StockItemPageGQLQueries";

type SmCardProps = {
    stockMovement: StockMovement;
    deleteCallBack: (id: string) => void;
};
type StockMovementsPageState = {
    stockMovement: StockMovement;
    editing: boolean;
};
export default function SmCard({ stockMovement, deleteCallBack }: SmCardProps) {
    const [pageState, setPageState] = useState<StockMovementsPageState>({
        editing: false,
        stockMovement: {
            id: stockMovement.id,
            note: stockMovement.note,
            date: stockMovement.date,
            movementAmount: stockMovement.movementAmount,
            reason: stockMovement.reason,
            stockItem: stockMovement.stockItem,
        },
    });
    return (
        <div className="flex  flex-col p-3 bg-gray-100 rounded w-full justify-evenly items-center mb-2  sm:flex-row">
            <div className="flex justify-center items-center sm:flex-col ">
                <h3>Reason</h3>
                <input
                    type="text"
                    value={pageState.stockMovement.reason}
                    className="p-3 m-2 rounded"
                    placeholder="Reason"
                    disabled={!pageState.editing}
                    onChange={(e) => {
                        setPageState({
                            ...pageState,
                            stockMovement: {
                                ...pageState.stockMovement,
                                reason: e.target.value,
                            },
                        });
                    }}
                />
            </div>
            <div className="flex justify-center items-center sm:flex-col ">
                <h3>Amount</h3>
                <input
                    type="number"
                    value={pageState.stockMovement.movementAmount}
                    className="p-3 m-2 rounded"
                    placeholder="Movement Amount"
                    disabled={!pageState.editing}
                    onChange={(e) => {
                        setPageState({
                            ...pageState,
                            stockMovement: {
                                ...pageState.stockMovement,
                                movementAmount: parseInt(e.target.value),
                            },
                        });
                    }}
                />
            </div>
            <div className="flex justify-center items-center sm:flex-col ">
                <h3>Note</h3>
                <input
                    type="text"
                    value={pageState.stockMovement.note}
                    className="p-3 m-2 rounded"
                    placeholder="Note"
                    disabled={!pageState.editing}
                    onChange={(e) => {
                        setPageState({
                            ...pageState,
                            stockMovement: {
                                ...pageState.stockMovement,
                                note: e.target.value,
                            },
                        });
                    }}
                />
            </div>
            <div className="flex justify-center items-center sm:flex-col ">
                <h3>Date</h3>
                <input
                    type="date"
                    value={pageState.stockMovement.date.slice(0, 10)}
                    className="p-3 m-2 rounded"
                    placeholder="Date"
                    disabled={!pageState.editing}
                    onChange={(e) => {
                        setPageState({
                            ...pageState,
                            stockMovement: {
                                ...pageState.stockMovement,
                                date: e.target.value,
                            },
                        });
                    }}
                />
            </div>
            {pageState.editing ? (
                <ButtonTeal
                    content={"Save"}
                    onClick={function (): void {
                        const valid: ValidatorResponse = validateUpdateSmInput(
                            pageState.stockMovement
                        );
                        if (!valid.valid) {
                            let output = "";
                            valid.errors?.forEach((errMsg) => {
                                output += errMsg + "\n";
                            });
                            alert(`${output}`);
                            return;
                        }
                        client
                            .mutate({
                                mutation: GET_UPDATE_SM_QUERY(
                                    pageState.stockMovement
                                ),
                            })
                            .then((data) => {
                                console.log(data);

                                alert("Success");
                                setPageState({ ...pageState, editing: false });
                            });
                    }}
                    classNameAdd={""}
                />
            ) : (
                <ButtonTeal
                    content={"Edit"}
                    onClick={function (e: any): void {
                        setPageState({ ...pageState, editing: true });
                    }}
                    classNameAdd={""}
                />
            )}
            <ConfirmDelete
                deleteFunc={() => {
                    client
                        .mutate({
                            mutation: GET_DELETE_SM_QUERY(
                                pageState.stockMovement.id
                            ),
                        })
                        .then((data) => {
                            console.log(data);
                            deleteCallBack(pageState.stockMovement.id);
                        });
                }}
            />
        </div>
    );
}
