import { useState } from "react";
import { Button } from "semantic-ui-react";
// import { gql, useMutation } from "@apollo/client";

import cowCollection from "../../util/cowCollectionClass";
import groupCollection from "../../util/groupCollectionClass";
import RenderGroupOptions from "../../util/helpers/RenderGroupOptions";
type ManageGroupsProps = {
    cows: cowCollection;
    groups: groupCollection;
};
export default function ManageGroups({ cows, groups }: ManageGroupsProps) {
    // const { groups, cows } = useContext(WorkingContext);
    const [values, setValues] = useState({
        name: "",
        groupId: "add",
    });

    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setStartValues = (groupId: any) => {
        console.log(groupId);
        // setAddFlag(false);
        if (groupId === "add") {
            setValues({
                ...values,
                groupId: groupId,
                name: "",
            });
            return null;
        }
        groups.getGroups().forEach((group: any) => {
            // console.log(group);
            if (group.getId() === groupId) {
                setValues({
                    ...values,
                    groupId: groupId,
                    name: group.getName(),
                });
            }
        });
    };

    return (
        <div className="p-3 bg-gray-100 rounded">
            <div className="rounded p-3 text-center bg-gray-100">
                <select
                    className=" rounded border-2  p-2 text-black focus:outline-none focus:border-teal-200 w-40"
                    placeholder="groupName"
                    value={values.groupId}
                    onChange={(e) => {
                        // onChange(e);
                        setStartValues(e.target.value);
                    }}
                >
                    <option value="add">Add Group</option>
                    <RenderGroupOptions groups={groups.getGroups()} />
                </select>
                <Button
                    color="green"
                    icon="plus"
                    className="m-2"
                    onClick={() => {
                        setStartValues("add");
                    }}
                />

                <div className="rounded flex flex-col items-center justify-evenly">
                    <input
                        type="text"
                        placeholder="Name"
                        className="w-46 m-1 p-3 border-2 rounded focus:outline-none focus:border-teal-200 text-black"
                        name="name"
                        value={values.name}
                        onChange={onChange}
                    />

                    <Button
                        color={values.groupId === "add" ? "grey" : "red"}
                        content="  Delete group  "
                        className="w-40 mb-2"
                        onClick={() => {
                            if (values.groupId === "add") {
                                return;
                            }
                            let check = true;

                            cows.getCows().forEach((cow: any) => {
                                if (cow.getCGId() === values.groupId) {
                                    check = false;
                                }
                            });
                            if (!check) {
                                alert(
                                    "Cannot Delete! please remove all cows from group before deleting"
                                );
                                return;
                            } else {
                                alert("Success");
                                groups.removeGroup(values.groupId);
                            }
                        }}
                    />
                    <Button
                        color="green"
                        content={
                            values.groupId === "add"
                                ? "Add Group"
                                : "Confirm changes"
                        }
                        className="w-40 mb-2"
                        onClick={() => {
                            if (values.groupId === "add") {
                                groups.addGroup(values.name);
                            } else if (values.groupId !== "add") {
                                groups.updateGroup(values.groupId, values.name);
                                // updateMed();
                            } else {
                                console.log("Not MAdE YeT");
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
