import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import indivCow from "../../../util/indivCowClass";
import { CowMedEntry } from "../../../util/Types/CowmedEntry";
import EditMedCard from "./EditMedCard";

import { FETCH_MEDS_QUERY } from "../pageHelpersAndConfig/PageGQLQueries";
import { useQuery } from "@apollo/client";
import { medType } from "../../../util/Types/MedType";
import Loading from "../../../components/Loading";

type Props = {
    cow: indivCow;
};

type pageState = Array<false | CowMedEntry>;

export default function AniEditMedsComponent({ cow }: Props) {
    const [cowMedArr, setCowMedArr] = useState<pageState>([
        ...cow.medicineHistory,
    ]);
    const [medList, setMedList] = useState<Array<medType>>([]);

    const { error, loading } = useQuery(FETCH_MEDS_QUERY, {
        onCompleted: (data) => {
            console.log(data.getMeds);
            setMedList(data.getMeds);
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (loading) return <Loading />;

    return (
        <div className="bg-gray-300 text-black  rounded p-3 col-start-1 col-end-4 ">
            <div className="flex items-center justify-center">
                <h1 className="m-0 p-0"> Meds</h1>

                <button
                    className="bg-teal-400 ml-3 my-2 hover:bg-teal-600 p-3 rounded"
                    onClick={() => {
                        setCowMedArr([false, ...cowMedArr]);
                    }}
                >
                    <FaPlus />
                </button>
            </div>
            <div className="bg-gray-100 rounded p-3 overflow-x-scroll max-w-full flex min-h-r15">
                {cowMedArr.map((med, index) => {
                    return (
                        <EditMedCard
                            key={index}
                            cowMedEntry={med !== false ? med : undefined}
                            meds={medList}
                            cow={cow}
                        />
                    );
                })}
            </div>
        </div>
    );
}
