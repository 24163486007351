import React from "react";
import { FeedLogType } from "../../util/Types/FeedLogType";
import { groupType } from "../../util/Types/GroupType";

type feedCardProps = {
    group: groupType;
    logs: Array<FeedLogType>;
};
export default function FeedCard({ group, logs }: feedCardProps) {
    // const group = groupState
    //     .getGroups()
    //     .find((group) => group.getId() === groupId);
    // console.log(logs);

    return (
        <div className="  bg-gray-300 rounded mx-2 mt-2 flex flex-col items-center sm:flex-row justify-evenly  p-8 ">
            <h1 className="m-0 p-0">{group ? group.name : "N/A"}</h1>
            <h1 className="m-0 p-0">
                Total Fed: [
                {logs.reduce((total, log) => {
                    // console.log(log.mass);
                    return total + log.mass;
                }, 0)}
                ]
            </h1>
        </div>
    );
}
