import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import GroupSelect from "../../../components/InputComponents/GroupSelect";
import indivCow from "../../../util/indivCowClass";
import { groupType } from "../../../util/Types/GroupType";

type Props = { cow: indivCow; groups: Array<groupType> };
// TODO: fix page refresh on save
export default function AniEditGroupInput({ cow, groups }: Props) {
    const [pageState, setpageState] = useState({
        changed: false,
        groupId: cow.getCGId(),
    });
    return (
        <div className="col-start-2 col-end-3  bg-gray-300  text-black rounded p-3 flex flex-col items-center">
            <h2 className="inline max-w-full ">Group:</h2>

            <GroupSelect
                onChange={(e) => {
                    setpageState({ changed: true, groupId: e.target.value });
                }}
                groups={groups}
                value={pageState.groupId}
            />

            <button
                className={`bg-gray-400 mt-2 hover:bg-gray-300 text-black p-3 rounded ml-3 ${
                    pageState.changed ? "bg-teal-400  hover:bg-teal-600 " : ""
                }`}
                onClick={() => {
                    cow.moveGroup(pageState.groupId);
                }}
            >
                <FaSave />
            </button>
        </div>
    );
}
