import React, { useState } from "react";
import campRepGroup from "../../util/campRep/campRepGroupClass";

import WeighIn from "./WeighIn";

type campCardProps = {
    group: campRepGroup;
};
export default function CampCard({ group }: campCardProps) {
    const [display, setDisplay] = useState(false);
    const [sessionState, setSessionState] = useState(group.getDates());
    const sessions = group.getSessions();

    return (
        <div className="  bg-gray-300 rounded mx-2 mt-2 flex flex-col items-center justify-evenly p-8 ">
            <div className="flex items-center justify-between p-0 h-full  rounded bg-gray-300 text-black w-full">
                <div>
                    <h2 className="p-0 m-0">{group.getName()}</h2>
                    <h3 className="p-0 m-0">[{group.getCount()}] Cows</h3>
                    <h3 className="p-0 m-0">[{group.getTotalWeight()}] KG</h3>
                </div>
                <div>
                    {/* TODO: Remove "any" typing */}
                    {sessionState.map((date: any) => {
                        return (
                            <fieldset
                                className="m-0 p-0 inline-block ml-3"
                                key={date.date}
                            >
                                <input
                                    type="checkbox"
                                    name={date.date}
                                    value={date}
                                    checked={date.include || false}
                                    onChange={(e) => {
                                        setSessionState(
                                            group.toggleDate(e.target.name)
                                        );
                                    }}
                                />
                                {date.date.slice(0, 10)}
                            </fieldset>
                        );
                    })}
                </div>
                <button
                    className="bg-teal-400  hover:bg-teal-600 p-3 rounded"
                    onClick={() => {
                        setDisplay(!display);
                    }}
                >
                    Display {display ? "less" : "more"}
                </button>
            </div>
            {display ? (
                <div className="w-full">
                    {sessions.map((session: any) => {
                        return (
                            <WeighIn
                                // group={group}
                                session={session}
                                key={session.date}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}
