import { useState } from "react";
import { Button } from "semantic-ui-react";
import cowCollection from "../../util/cowCollectionClass";
import groupCollection from "../../util/groupCollectionClass";
import AddSubComp from "./editSubComponents/AddSubComp";

type AddCowsProps = {
    cows: cowCollection;
    groups: groupCollection;
};
export default function AddCowsTab({ groups, cows }: AddCowsProps) {
    const [addArr, setAddArr] = useState([Math.random()]);

    return (
        <div className={`"container`}>
            <Button
                color="green"
                icon="plus"
                onClick={() => {
                    setAddArr([Math.random(), ...addArr]);
                }}
                className=" py-4 w-full mb-2 mt-3"
            />

            <div
                className={`${"pt-15 p-3 h-vh70 "}flex flex-col overflow-y-scroll rounded `}
            >
                {addArr.map((el) => {
                    return <AddSubComp key={el} cows={cows} groups={groups} />;
                })}
            </div>
        </div>
    );
}
