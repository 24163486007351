import React from "react";
import { FaSort } from "react-icons/fa";

type SortMenuItemProps = {
    updateParentSort(sortVal: string): void;
    updateParentFlip(): void;
};
export default function SortMenuItem({
    updateParentFlip,
    updateParentSort,
}: SortMenuItemProps) {
    return (
        <div className="p-1">
            <h2 className="my-0 mr-2">Sort</h2>

            <div className="flex">
                <select
                    className="w-32 border-2 border-gray-200 p-2 rounded"
                    onChange={(e) => {
                        updateParentSort(e.target.value || "tagno");
                    }}
                >
                    <option value="tagno" className="p-2">
                        TagNo
                    </option>
                    <option value="groupname" className="p-2">
                        Group Name
                    </option>
                    <option value="weight" className="p-2">
                        Weight
                    </option>
                </select>

                <button
                    className="bg-teal-400  hover:bg-teal-600 ml-2 p-3 rounded"
                    onClick={() => {
                        updateParentFlip();
                    }}
                >
                    <FaSort className="m-0 p-0" />
                </button>
            </div>
        </div>
    );
}
