import React, { useContext, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { gql, useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../context/auth";

let User = {};

export default function LoginPage(props) {
    const context = useContext(AuthContext);
    const [values, setValues] = useState({
        username: "",
        password: "",
    });

    const loginQuery = gql`
  mutation{
      login(username: "${values.username}", password: "${values.password}"){
      id
      username
      email
      createdAt
      token

    }}`;

    const [returnUser] = useMutation(loginQuery, {
        onError: (e) => console.log(e),
        onCompleted: (data) => {
            User = { ...data.login };
            User.loggedIn = true;
            // console.log(User)
            // props.sendUser(User)
            context.login(User);
        },
    });

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const OnSubmit = (e) => {
        e.preventDefault();
        returnUser();
    };

    if (User.loggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="h-vh80  flex flex-col justify-center items-center flex-grow">
            <div className="container">
                <Form onSubmit={OnSubmit} noValidate inverted>
                    <h1 className="xl:text-6xl">Log In</h1>
                    <Form.Input
                        required
                        label="Username"
                        placeholder="Username"
                        name="username"
                        value={values.username}
                        onChange={onChange}
                        className="xl:text-xl"
                    />

                    <Form.Input
                        required
                        label="Password"
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={onChange}
                        className="xl:text-xl"
                    />
                    <Button type="submit" className="w-full lg:w-max xl:text-xl" >Log In</Button>
                </Form>
            </div>
        </div>
    );
}
