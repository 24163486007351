import React, { useState } from "react";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import { GET_CREATE_STOCKITEM_QUERY } from "./StockItemPageGQLQueries";
import { client } from "../../../ApolloProvider";
import { StockItem } from "../../../util/Types/StockItemType";
type addStockItemState = {
    created: boolean;
    creating: boolean;
    siName: string;
};
type AddStockItemProps = {
    AddStockItemcallBack: (stockItem: StockItem) => void;
};
export default function AddStockItem({
    AddStockItemcallBack,
}: AddStockItemProps) {
    const [pageState, setPageState] = useState<addStockItemState>({
        created: false,
        creating: false,
        siName: "",
    });

    return (
        <div className="p-3 bg-gray-300">
            <h3>Add Stock Item</h3>

            <div className="w-full flex flex-col justify-center items-center pb-10 ">
                <div className="flex flex-col items-start m-3">
                    <label htmlFor="item-name" className="text-black">
                        Stock Item Name
                    </label>
                    <input
                        type="text"
                        name="item-name"
                        id="item-name"
                        title="Stock Item Name"
                        className="p-2 rounded "
                        onChange={(e: any) => {
                            setPageState({
                                ...pageState,
                                siName: e.target.value,
                            });
                        }}
                    />
                </div>
                <ButtonTeal
                    content={"Add"}
                    onClick={() => {
                        client
                            .mutate({
                                mutation: GET_CREATE_STOCKITEM_QUERY(
                                    pageState.siName
                                ),
                            })
                            .then((data) => {
                                console.log(data);
                                const input: any =
                                    document.getElementById("item-name");
                                AddStockItemcallBack(data.data.createStockItem);
                                if (input) {
                                    input.value = "";
                                }
                            });
                    }}
                    classNameAdd={"w-1/3"}
                />
            </div>
        </div>
    );
}
