import React from 'react';
import App from './App';
import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";

// const httpLink = createHttpLink({
//   uri: 'https://cowtrack.adevsa.com/'
// });

// console.log(process.env)
const httpLink = createHttpLink({

  uri: process.env.REACT_APP_APIURI || 'http://localhost:9001/'//'https://cowtrack.herokuapp.com/'

});

const errorlink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      alert(`an Error has occurred:
        ${message}
      `)
      return message
    }
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(() => {
  const token = localStorage.getItem('jwtToken')
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const link = ApolloLink.from([authLink, errorlink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});


const ApolloProvide = (<ApolloProvider client={client}><App /></ApolloProvider>)
export { client, ApolloProvide }
// export default (
//   <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>
// );
