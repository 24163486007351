// import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { FaTrash } from 'react-icons/fa'
// import { WorkingContext } from "../../../context/workContext";

export default function ConfirmDelete({ deleteFunc }) {
    const [open, setOpen] = useState(false);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <button className="  bg-red-600 hover:bg-red-700 p-3 mr -1 rounded text-white">
                    <FaTrash />
                </button>
            }
        >
            <Modal.Header className="bg-gray-100">Are you sure?</Modal.Header>

            <Modal.Content className="bg-gray-100 text-black">
                <h1>Confirm deletion</h1>
            </Modal.Content>

            <Modal.Actions className="bg-gray-100">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button
                    color="red"
                    onClick={() => {
                        deleteFunc();
                        setOpen(false);
                    }}
                >
                    Yes, Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
