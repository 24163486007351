import { client } from "../ApolloProvider"
import { gql } from '@apollo/client'

class feed {
    constructor(feedObj) {
        this.id = feedObj.id
        this.name = feedObj.feedName
        this.weightRange = feedObj.preferredWeightRange
        this.notes = feedObj.notes
        this.distribution = feedObj.distribution
        this.sessions = feedObj.sessions
    }

    getId() {
        return this.id
    }
    getName() {
        return this.name
    }



    updateDistribution(feedId, distributionObj) {
        let distributionArr = []
        for (const index in distributionObj) {
            if (index !== "selectedFeed") {

                const entry = distributionObj[index]
                // console.log(entry)
                distributionArr.push(`{groupName : "${entry.name}", groupId : "${entry.id}" ,percentage : ${entry.percentage}}`)
            }
        }
        const UPDATEQUERY = gql`
        mutation{
            updateFeed(feedId: "${feedId}",          UpdateFeedInput: {distribution: [${distributionArr}]})
            {
                 ${STDFIELDS}
             }
        }
        `
        console.log(UPDATEQUERY)
        client.mutate({ mutation: UPDATEQUERY }).then((data) => {
            console.log(data)
            // this.logs.push(data.data.updateFeed)

        }).catch((err) => {
            console.log(err)
        })
    }

}

export default class feedCollection {
    constructor(logState) {
        this.feeds = logState.map(log => new feed(log))
        // console.log(this.feeds)
    }
    getFeed() {
        return this.feeds
    }
    getById(feedId) {
        return this.feeds.find((feed) => feed.getId() === feedId)
    }
    createFeed(feedName, weightRange) {
        const CREATEQUERY = gql`
        mutation{
            createFeed(CreateFeedInput: {feedName: "${feedName}", weightRange: [${weightRange}]}){
            ${STDFIELDS}
            }
        }
        `

        client.mutate({ mutation: CREATEQUERY }).then((data) => {
            console.log(data)
            // this.logs.push(data.data.updateFeed)

        }).catch((err) => {
            console.log(err)
        })
    }
    deleteFeed(feedId) {

        const DELTEQUERY = gql`
        mutation{
            deleteFeed(feedId: "${feedId}" )
        }
    `
        client.mutate({ mutation: DELTEQUERY }).then((data) => {
            console.log(data)
            // this.logs.push(data.data.updateFeed)

        }).catch((err) => {
            console.log(err)
        })


    }
    updateSessions(feedId, sessionArr) {
        let sessArr = []
        sessionArr.forEach((session) => {
            sessArr.push(`{description: "${session.description}", amount: ${session.amount}}`)
        })


        const UPDATEQUERY = gql`
        mutation{
         updateFeed(feedId: "${feedId}",
         UpdateFeedInput: {sessions:[${sessArr}]}
         ){
    ${STDFIELDS}
  }
}
        `
        client.mutate({ mutation: UPDATEQUERY }).then((data) => {
            console.log(data)
            // this.logs.push(data.data.updateFeed)

        }).catch((err) => {
            console.log(err)
        })
    }

    updateFeed(feedId, feedName, weightRange, notes) {
        const UPDATEQUERY = gql`
        mutation{
        updateFeed(feedId: "${feedId}",UpdateFeedInput: {feedName: "${feedName}",weightRange :[${weightRange}], notes: "${notes}"
         }){
        ${STDFIELDS}
        }
        }
        `


        client.mutate({ mutation: UPDATEQUERY }).then((data) => {
            console.log(data)
            // this.logs.push(data.data.updateFeed)

        }).catch((err) => {
            console.log(err)
        })

    }
}

const STDFIELDS = `
feedName
preferredWeightRange
 notes
distribution{
  groupName
  groupId
  percentage
}
sessions{
  description
  amount
}`