import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import TabNavigate from "../../../components/TabNavigate/TabNavigate";
import { FETCH_ALL_STOCKITEMS_QUERY } from "./StockItemPageGQLQueries";
import ViewStockItems from "./ViewStockItems";
import Loading from "../../../components/Loading";
import { StockItem } from "../../../util/Types/StockItemType";
import AddStockItem from "./AddStockItem";

type StockItemPageState = {
    fetched: boolean;
    stockItems: StockItem[];
};
export default function StockItemPage() {
    const [pageState, setPageState] = useState<StockItemPageState>({
        fetched: false,
        stockItems: [],
    });
    let tabs = [
        {
            tabIndex: "STOCK_ITEM",
            buttonText: "View",
            element: (
                <ViewStockItems
                    stockItems={[...pageState.stockItems]}
                    deleteCallBack={(id: String): void => {
                        // console;
                        setPageState({
                            ...pageState,
                            stockItems: [...pageState.stockItems].filter(
                                (si) => si.id !== id
                            ),
                        });
                    }}
                />
            ),
        },
        {
            tabIndex: "ADD_STOCK_ITEM",
            buttonText: "Add",
            element: (
                <AddStockItem
                    AddStockItemcallBack={(stockItem: StockItem) => {
                        const sItems = [...pageState.stockItems];
                        sItems.push(stockItem);
                        setPageState({
                            ...pageState,
                            stockItems: sItems,
                        });
                    }}
                />
            ),
        },
    ];

    const { error } = useQuery(FETCH_ALL_STOCKITEMS_QUERY, {
        onCompleted: (data) => {
            console.log(data.getStockItems);

            setPageState({
                ...pageState,

                fetched: true,
                stockItems: data.getStockItems,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    console.log(pageState.stockItems);
    return (
        <div className="bg-gray-100 rounded mt-0 pt-3 text-black">
            <TabNavigate startTab={tabs[0]} tabArray={tabs} />
        </div>
    );
}
