import React, { useState } from "react";
import { StockItem } from "../../../util/Types/StockItemType";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import ConfirmDelete from "../../../components/ConfirmDelete";
import {
    GET_DELETE_STOCKITEM_QUERY,
    GET_FETCH_SOH_QUERY,
} from "./StockItemPageGQLQueries";
import { useQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import { client } from "../../../ApolloProvider";

type StockItemCardState = {
    fetched: boolean;
    SOH: number | "N/A";
};
type StockItemCardProps = {
    stockItem: StockItem;
    deleteCallBack: (id: String) => void;
    viewCallBack: (si: StockItem) => void;
};
export default function StockItemCard({
    stockItem,
    deleteCallBack,
    viewCallBack,
}: StockItemCardProps) {
    const [pageState, setPageState] = useState<StockItemCardState>({
        fetched: false,
        SOH: "N/A",
    });
    const { error } = useQuery(GET_FETCH_SOH_QUERY(stockItem.id), {
        onCompleted: (data) => {
            console.log(data.getSOH);

            setPageState({
                ...pageState,

                fetched: true,
                SOH: data.getSOH ? data.getSOH : "N/A",
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    return (
        <div className="p-3 bg-gray-100 rounded m-1 flex justify-between w-full items-center">
            <div className="w-1/3">
                <h3>Name</h3>
                {stockItem.stockItemName}
            </div>
            <div>
                <h3>SOH</h3>
                {`${pageState.SOH}`.slice(0, 8)}
            </div>
            <div className="flex justify-evenly items-center ">
                <ButtonTeal
                    content={"View"}
                    onClick={function (): void {
                        viewCallBack(stockItem);
                    }}
                    classNameAdd={"mr-2 text-xs"}
                />
                <ConfirmDelete
                    deleteFunc={() => {
                        client
                            .mutate({
                                mutation: GET_DELETE_STOCKITEM_QUERY(
                                    stockItem.id
                                ),
                            })
                            .then((data) => {
                                console.log(data);
                                deleteCallBack(stockItem.id);
                            });
                    }}
                />
            </div>
        </div>
    );
}
