import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import indivCow from "../../../util/indivCowClass";

type Props = {
    cow: indivCow;
};
export default function AniEditOriginInput({ cow }: Props) {
    const [pageState, setpageState] = useState({
        changed: false,
        origin: cow.getOrigin(),
    });
    return (
        <div className="border-2 col-start-3 col-end-4  bg-gray-300  text-black rounded p-3 flex flex-col items-center">
            <h2 className="inline max-w-full">Origin:</h2>
            <input
                type="text"
                name="origin"
                defaultValue={pageState.origin}
                className=" border-2 border-gray-200  max-w-full bg-white p-3 w-24 rounded ml-3"
                onChange={(e) => {
                    setpageState({ changed: true, origin: e.target.value });
                }}
            />

            <button
                className={`bg-gray-400 mt-2 hover:bg-gray-300 text-black p-3 rounded ml-3 ${
                    pageState.changed ? "bg-teal-400  hover:bg-teal-600 " : ""
                }`}
                onClick={() => {
                    if (!pageState.changed) {
                        return null;
                    } else {
                        cow.updateOrigin(pageState.origin);
                    }
                }}
            >
                <FaSave />
            </button>
        </div>
    );
}
