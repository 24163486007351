import React from "react";
import PagnationNav from "./PagnationNav";

type listViewProps = {
    DisplayItems: Array<JSX.Element>;
    pageUpHandler(): void;
    pageDownHandler(): void;
    getPageCount(): number;
    getCurrentPage(): number;
};

/**
 * Test Docstring description
 * @param param0
 * @returns
 */
export default function PaginatedListView({
    DisplayItems,
    pageDownHandler,
    pageUpHandler,
    getPageCount,
    getCurrentPage,
}: listViewProps) {
    return (
        <div className="w-full h-full bg-gray-300 text-black flex-col flex items-center justify-start overflow-y-auto">
            <PagnationNav
                pageDownHandler={pageDownHandler}
                pageUpHandler={pageUpHandler}
                getCurrentPage={getCurrentPage}
                getPageCount={getPageCount}
            />
            {/* <p className="font-black">pagination!</p> */}
            {DisplayItems}
            <PagnationNav
                pageDownHandler={pageDownHandler}
                pageUpHandler={pageUpHandler}
                getCurrentPage={getCurrentPage}
                getPageCount={getPageCount}
            />
        </div>
    );
}
