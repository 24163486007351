import simpleValidatorResponse from "./simpleValidatorResponse";
import ValidatorResponse from "../Types/ValidatorResponseInterface";

const func = (tagNo: String) : ValidatorResponse=>{
    
    if(tagNo.trim() === ""){
        return new simpleValidatorResponse(false, ["TagNo Cannot be empty"]);
    }
    return new simpleValidatorResponse((true))
}
export default func