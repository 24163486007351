import React, { useState } from "react";
import { client } from "../../../../ApolloProvider";
import ButtonTeal from "../../../../components/InputComponents/ButtonTeal";
import { StockItem } from "../../../../util/Types/StockItemType";
import { StockMovement } from "../../../../util/Types/StockMovementType";
import ValidatorResponse from "../../../../util/Types/ValidatorResponseInterface";
import {
    createSmInput,
    validateCreateSmInput,
} from "../../../../util/validators/ValidateStockMovement";
import { GET_ADD_SM_QUERY } from "../StockItemPageGQLQueries";

type StockMovementsPageState = {
    stockMovement: createSmInput;
};
type StockMovementsPageProps = {
    stockItem: StockItem;
    addSmCallback: (sm: StockMovement) => void;
};
export default function AddSMInput({
    stockItem,
    addSmCallback,
}: StockMovementsPageProps) {
    const [pageState, setPageState] = useState<StockMovementsPageState>({
        stockMovement: {
            note: "",
            date: "",
            mvAmt: 0,
            reason: "",
            siID: stockItem.id,
        },
    });
    return (
        <div className="w-full flex  flex-col justify-between items-center sm:flex-row">
            <input
                type="text"
                className="p-3 m-2 rounded"
                placeholder="Note"
                onChange={(e) => {
                    setPageState({
                        ...pageState,
                        stockMovement: {
                            ...pageState.stockMovement,
                            note: e.target.value,
                        },
                    });
                }}
            />
            <input
                type="text"
                className="p-3 m-2 rounded"
                placeholder="Reason"
                onChange={(e) => {
                    setPageState({
                        ...pageState,
                        stockMovement: {
                            ...pageState.stockMovement,
                            reason: e.target.value,
                        },
                    });
                }}
            />
            <input
                type="number"
                className="p-3 m-2 rounded"
                placeholder="Movement Amount"
                onChange={(e) => {
                    setPageState({
                        ...pageState,
                        stockMovement: {
                            ...pageState.stockMovement,
                            mvAmt: parseInt(e.target.value),
                        },
                    });
                }}
            />
            <input
                type="date"
                className="p-3 m-2 rounded"
                placeholder="Date"
                onChange={(e) => {
                    setPageState({
                        ...pageState,
                        stockMovement: {
                            ...pageState.stockMovement,
                            date: e.target.value,
                        },
                    });
                }}
            />

            <ButtonTeal
                content={"ADD"}
                onClick={function (): void {
                    const valid: ValidatorResponse = validateCreateSmInput(
                        pageState.stockMovement
                    );
                    if (!valid.valid) {
                        let output = "";
                        valid.errors?.forEach((errMsg) => {
                            output += errMsg + "\n";
                        });
                        alert(`${output}`);
                        return;
                    }
                    client
                        .mutate({
                            mutation: GET_ADD_SM_QUERY(pageState.stockMovement),
                        })
                        .then((data) => {
                            console.log(data);

                            alert("Success");
                            addSmCallback(data.data.addStockMovement);
                        });
                }}
                classNameAdd={"mb-1"}
            />
        </div>
    );
}

// siID: ID!
// note: String
// date: String!
// mvAmt: Float!
// reason: String!
