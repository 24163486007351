/**
 * Function to generate an Array of other Arrays used to help make Paginated pages
 * @param array Array of items to be Paginated
 * @param length Amount of items per page
 * @returns Array conataining Other Arrays, eacbh sub array representing the content on a page. Best done by returning a JSX.Element
 */
export function makePageArray(array:Array<any>, length: number) : Array<Array<any>>{
    // generates 2 dimensional arrays to paginate
    let counter = 0;
    let pageArr = [];
    let holder_array : Array<any>= [];
    array.forEach((item: any) => {
        counter++
        if (counter <= length) {
            holder_array.push(item)
            return
        }
        pageArr.push(holder_array)
        counter = 1
        holder_array = []
        holder_array.push(item)
    })
    pageArr.push(holder_array)
    // console.log(array.length)
    // console.log(pageArr)
    return pageArr;
}

/**
 * Function used to help in creating a page up handler. returns the next page/index for given page array.
 * @param currentPage Current page number/index
 * @param pageArray Array referenced by current page/index
 * @returns Next page/index or currrent if it is the last available
 */
export function pageUp(currentPage: number, pageArray?: Array<Array<any>>): number {
    if (!pageArray){throw new Error("PageArray is required")}
    if (currentPage >= pageArray.length -1) {
        return currentPage
    }
    // setCurrentPage(currentPage + 1);
    return currentPage + 1
};

/**
 * Function used to help in creating a page up handler. returns the previous page/index for given page array. 
 * @param currentPage Current page number/index
 * @returns previous page/index numebr or current page if it would be below 0
 */
export function pageDown(currentPage:number) : number{
    if (currentPage <= 0) {
        return currentPage;
    }
    return currentPage - 1;
};