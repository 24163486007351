//! Lib imports
import React from "react";
import { Route, Switch } from "react-router-dom";

//! page imports

import Home from "./Pages/HomePage/Home";
import LoginPage from "./Pages/LoginPage/LoginPage";
import Animals from "./Pages/AnimalsPage/Animals";
// import Settings from './components/Settings'
import Reporting from "./Pages/ReportingPage/ReportingPage";
import Feed from "./Pages/FeedsPage/FeedPage";
import Admin from "./Pages/AdminPage/Admin";

//!component Imports
import PrivateRoute from "./components/PrivateRoute";
import StockPage from "./Pages/StockPage/StockPage";

const Router = () => {
    return (
        <Switch>
            {/* public routes */}
            <Route exact path="/login" render={() => <LoginPage />} />

            {/* Private Routes */}
            <PrivateRoute exact path="/" Comp={Home} redirectPath={"/login"} />
            <PrivateRoute
                exact
                path="/animals"
                Comp={Animals}
                redirectPath={"/login"}
            />
            <PrivateRoute
                exact
                path="/feed"
                Comp={Feed}
                redirectPath={"/login"}
            />
            <PrivateRoute
                exact
                path="/reporting"
                Comp={Reporting}
                redirectPath={"/login"}
            />
            <PrivateRoute
                exact
                path="/admin"
                Comp={Admin}
                redirectPath={"/login"}
            />
            <PrivateRoute
                exact
                path="/stock"
                Comp={StockPage}
                redirectPath={"/login"}
            />

            {/* Routes to be removed/implemented later */}
            {/* <Route exact path="/settings" component={Settings} /> */}
        </Switch>
    );
};

export default Router;
