import { client } from "../ApolloProvider"
import { gql } from '@apollo/client'

export default class indivCow {
    constructor(cowObj) {
        this.id = cowObj.id
        this.tagNo = cowObj.tagNo
        this.groups = cowObj.groups
        this.avgGrowthLifetime = cowObj.avgGrowthLifetime
        this.weights = cowObj.weights
        this.medicineHistory = cowObj.medicineHistory
        this.origin = cowObj.origin ? cowObj.origin : "N/A"
    }
    updateData(cowObj) {
        console.log(cowObj)
        this.id = cowObj.id
        this.tagNo = cowObj.tagNo
        this.groups = cowObj.groups
        this.avgGrowthLifetime = cowObj.avgGrowthLifetime
        this.weights = cowObj.weights
        this.medicineHistory = cowObj.medicineHistory
        this.origin = cowObj.origin ? cowObj.origin : "N/A"
    }

    getId() {
        return this.id
    }
    getTag() {
        return this.tagNo
    }
    getGroups() {
        return this.groups
    }
    getAvgL() {
        return this.avgGrowthLifetime
    }
    getWeights() {
        return this.weights
    }
    getMedHist() {
        return this.medicineHistory
    }
    getOrigin() {
        return this.origin
    }
    getCG() {
        if (!this.groups.currentGroupName) {
            return "unassigned"
        }
        return this.groups.currentGroupName
    }
    getCGId() {
        if (!this.groups.currentGroupID) {
            return "unassigned"
        }
        return this.groups.currentGroupID
    }
    getLifetimeAvg() {
        const weights = this.weights
        if (weights.length >= 2) {
            const first = weights[0]
            const last = weights[weights.length - 1]
            const lifetimeGrowth = last.weight - first.weight
            const lifeTime = new Date(last.date) - new Date(first.date)

            const result = lifetimeGrowth / (lifeTime / (1000 * 60 * 60 * 24))
            return result.toFixed(3)
        } else return "N/A"
    }
    getLatestWith() {
        const medHist = this.medicineHistory
        if (medHist.length >= 1) {
            const hist = [...medHist]
            hist.sort(function (a, b) {
                return (new Date(a.if_withdrawalEnd) - new Date(b.if_withdrawalEnd))
            })
            return hist[hist.length - 1].if_withdrawalEnd.slice(0, 10)
        } else return "N/A"
    }
    getWeightByDate(date) {
        const weight = this.weights.filter(weight => weight.date === date)
        // console.log(weight)
        // let weights = { weight: weight.length === 1 ? weight[0] : "N/A", cowId: this.id, tagNo: this.tagNo }
        return { weight: weight.length === 1 ? weight[0] : "N/A", cowId: this.id, tagNo: this.tagNo }
    }
    getLW() {
        const weights = this.weights
        return weights.length >= 1 ? weights[weights.length - 1].weight : "N/A"
    }
    getFW() {
        const weights = this.weights
        return weights.length >= 1 ? weights[0].weight : "N/A"
    }
    getLWDate() {
        const weights = this.weights
        return weights.length > 0 ? weights[weights.length - 1].date.slice(0, 10) : "N/A"
    }

    delete() {
        const DELETEQUERY = gql`
    mutation{
    deleteCow(cowId:"${this.id}")
    }
    `;
        client.mutate({ mutation: DELETEQUERY }).then((data) => {
            console.log(data)

        }).catch((err) => {
            console.log(err)
        })

    }

    updateTag(newTagNo) {
        const UPDATE_QUERY = gql`
    mutation{
  updateCowTag(cowId:"${this.id}", newTagNo:"${newTagNo}"){
    ${STDFIELDS}
  }
}
    `;

        client.mutate({ mutation: UPDATE_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.updateCowTag)

        }).catch((err) => {
            console.log(err)
        })

    }

    addWeight(date, weight, note) {
        const ADDWEIGHT_QUERY = gql`
    mutation{
	addCowWeight(cowId: "${this.id}", date:"${date}", weight: ${parseInt(weight)}, note: "${note}"){
		${STDFIELDS}
    }
  }`;
        client.mutate({ mutation: ADDWEIGHT_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.addCowWeight)

        }).catch((err) => {
            console.log(err)
        })
    }
    updateWeight(weightId, date, weight, note) {
        const UPDATEWEIGHT_QUERY = gql`
        mutation{
        updateCowWeight(
            weightId: "${weightId}" ,
            cowId: "${this.id}",
            date:"${date}",
            weight: ${parseInt(weight)},
            note: "${note}"){
        ${STDFIELDS}
        }
        }`;
        client.mutate({ mutation: UPDATEWEIGHT_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.updateCowWeight)

        }).catch((err) => {
            console.log(err)
        })
    }
    deleteWeight(weightId) {
        const DELETEWEIGHT_QUERY = gql`
            mutation {
                deleteCowWeight(
                    weightId: "${weightId}"
                    cowId: "${this.id}"
                ) {
                    ${STDFIELDS}
                }
            }
        `;
        client.mutate({ mutation: DELETEWEIGHT_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.deleteCowWeight)

        }).catch((err) => {
            console.log(err)
        })
    }
    addMed(medId, date, batchNo, note) {
        const ADDMED_QUERY = gql`
            mutation{
          addCowMed(cowId: "${this.id}", medId:"${medId}",  date: "${date}", batchNo: "${batchNo}", note: "${note}"){
            ${STDFIELDS}
          }
        }`;
        client.mutate({ mutation: ADDMED_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.addCowMed)

        }).catch((err) => {
            console.log(err)
        })

    }
    updateMed(medHistId, medId, date, batchNo, note) {
        const UPDATEMED_QUERY = gql`
        mutation{
            updateCowMed(medHistId: "${medHistId}",cowId:"${this.id}", medId: "${medId}", date:"${date}" ,batchNo:"${batchNo}" ,note: "${note}"){
            ${STDFIELDS}
          }
        }`;
        client.mutate({ mutation: UPDATEMED_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.updateCowMed)

        }).catch((err) => {
            console.log(err)
        })
    }
    deleteMed(medHistId) {
        const DELETEMED_QUERY = gql`
            mutation {
                deleteCowMed(
                    medHistId: "${medHistId}",
                    cowId: "${this.id}"
                ) {
                   ${STDFIELDS}
                }
            }
        `;
        client.mutate({ mutation: DELETEMED_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.deleteCowMed)

        }).catch((err) => {
            console.log(err)
        })
    }
    moveGroup(ngId) {
        const MOVEGROUP_QUERY = gql`
        mutation{
      updateCurrentGroup(cowId:"${this.id}", newGroupId:"${ngId}", moveDate: "${new Date().toISOString()}"){
        ${STDFIELDS}
      }
    }`;
        client.mutate({ mutation: MOVEGROUP_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.updateCurrentGroup)

        }).catch((err) => {
            console.log(err)
        })
    }
    updateOrigin(newOrigin) {
        const CHANGEORIGIN_QUERY = gql`
        mutation{
            updateCowOrigin(cowId:"${this.id}", newOrigin:"${newOrigin}"){
        ${STDFIELDS}
      }
    }`;
        client.mutate({ mutation: CHANGEORIGIN_QUERY }).then((data) => {
            console.log(data)
            this.updateData(data.data.updateCowOrigin)

        }).catch((err) => {
            console.log(err)
        })
    }
}
const STDFIELDS = ` id
tagNo
origin
groups{
    currentGroupID
    currentGroupName
    currentPeriodStart
        history{
            id
            groupId
            periodStart
        periodEnd
        }
}
weights{
    id
    date
    weight
    notes
}
medicineHistory{
    id
    medId
    date
    if_withdrawalEnd
    batchNo
    note
}`