// package imports
import { useState } from "react";

// component and helper imports
import MassCapWeights from "./editSubComponents/MassCapWeights";
import {
    makePageArray,
    pageUp,
    pageDown,
} from "../../util/helpers/paginationHelpers";
import ButtonTeal from "../../components/InputComponents/ButtonTeal";
import PrintWeighSheetComponent from "../../components/PrintWeighSheetComponent";
import RenderGroupOptions from "../../util/helpers/RenderGroupOptions";
import cowCollection from "../../util/cowCollectionClass";
import groupCollection from "../../util/groupCollectionClass";
import indivCow from "../../util/indivCowClass";

type weightsTabProps = {
    cows: cowCollection;
    groups: groupCollection;
};

// type filterType = { value: String };
export default function WeightsTab({ cows, groups }: weightsTabProps) {
    // const { groupState, cowState } = useContext(WorkingContext);
    const [currentGroup, setCurrentGroup] = useState("all");
    const [filterState, setFilterState] = useState({
        type: "group",
        value: "all",
        page: 0,
    });

    let cowArr: any = [];
    if (cows) {
        const cowHoldArr: Array<any> = cows.getCows().map((cow: indivCow) => {
            if (filterState.value !== "all") {
                if (filterState.type === "group") {
                    return {
                        cow: cow,
                        render: cow
                            .getCGId()
                            .toUpperCase()
                            .includes(filterState.value.toUpperCase()),
                    };
                } else if (filterState.type === "search") {
                    return {
                        cow: cow,
                        render: cow
                            .getTag()
                            .toUpperCase()
                            .includes(filterState.value.toUpperCase()),
                    };
                } else {
                    return { cow: cow, render: true };
                }
            } else {
                return { cow: cow, render: true };
            }
        });

        cowArr = makePageArray(
            cowHoldArr.filter((cow) => cow.render),
            20
        );
    }

    return (
        <div className="container">
            <div className="flex text-black bg-gray-300 p-1 overflow-x-scroll items-center justify-center mt-2 w-full sticky top-14 sm:top-16 z-10 ">
                <PrintWeighSheetComponent cowState={cows} groupState={groups} />

                <input
                    type="text"
                    placeholder="Search"
                    className="p-2 mx-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                    onChange={(e) => {
                        setFilterState({
                            type: "search",
                            value: e.target.value,
                            page: 0,
                        });
                    }}
                />

                <select
                    name="currentGroupId"
                    className="p-2 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                    value={currentGroup}
                    onChange={(e) => {
                        setCurrentGroup(e.target.value);
                        setFilterState({
                            type: "group",
                            value: e.target.value,
                            page: 0,
                        });
                    }}
                >
                    <option value="all">all Groups</option>
                    <option value="unassigned">Unassigned</option>
                    <RenderGroupOptions groups={groups.getGroups()} />
                </select>
            </div>

            <div className={`"flex mb-14 flex-col bg-gray-100 rounded p-3`}>
                {cowArr[filterState.page].map((cow: any) => {
                    return (
                        <MassCapWeights key={cow.cow.getId()} cowObj={cow} />
                    );
                })}
                <div className="bg-gray-300 p-2 m-0 rounded bottom-0 text-black flex left-0 w-full justify-evenly text-sm fixed">
                    <ButtonTeal
                        content="Prev"
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            setFilterState({
                                ...filterState,
                                page: pageDown(filterState.page),
                            });
                        }}
                        classNameAdd={""}
                    />
                    <h3 className="m-0 p-0">
                        {filterState.page + 1} of {cowArr.length}
                    </h3>
                    <ButtonTeal
                        content="next"
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            setFilterState({
                                ...filterState,
                                page: pageUp(filterState.page, cowArr),
                            });
                        }}
                        classNameAdd={""}
                    />
                </div>
            </div>
        </div>
    );
}
