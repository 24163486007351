import indivCow from "../../../util/indivCowClass"

const func = (StartData: Array<indivCow>,sortValue: String,
    flipVal: number):Array<indivCow> => {
        const sortData = [...StartData]
        sortData.sort((a: indivCow, b: indivCow) => {
            if (sortValue === "tagno") {
                return a.getTag().toUpperCase() < b.getTag().toUpperCase()
                    ? flipVal * -1
                    : flipVal * 1;
            } else if (sortValue === "groupname") {
                return a.getCG().toUpperCase() < b.getCG().toUpperCase()
                    ? flipVal * -1
                    : flipVal * 1;
            } else if (sortValue === "weight") {
                const aWeight =
                    a.weights.length > 0
                        ? a.weights[a.weights.length - 1].weight
                        : 0;

                const bWeight =
                    b.weights.length > 0
                        ? b.weights[b.weights.length - 1].weight
                        : 0;
                return aWeight < bWeight ? flipVal * -1 : flipVal * 1;
            } else {
                return a.getTag().toUpperCase() < b.getTag().toUpperCase()
                    ? flipVal * -1
                    : flipVal * 1;
            }
        });
        
    return sortData
}
export default func