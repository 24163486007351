import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import indivCow from "../../../util/indivCowClass";
import EditWeights from "./EditWeightCard";
type Props = {
    cow: indivCow;
};
export default function AniEditWeightsComponent({ cow }: Props) {
    const [weightArr, setWeightArr] = useState([...cow.getWeights()]);
    return (
        <div className="bg-gray-300  text-black  rounded p-3 col-start-1 col-end-4 ">
            <div className="flex items-center justify-center">
                <h1 className="m-0 p-0">Weights</h1>
                <button
                    className="bg-teal-400 ml-3 my-2 hover:bg-teal-600 p-3 rounded"
                    onClick={() => {
                        setWeightArr([
                            { id: "add", addFlag: true },
                            ...weightArr,
                        ]);
                    }}
                >
                    <FaPlus />
                </button>
            </div>
            <div className="bg-gray-100 rounded p-3 overflow-x-scroll max-w-full flex min-h-r15">
                {weightArr.map((weight) => {
                    return (
                        <EditWeights
                            key={weight.id}
                            weight={weight}
                            cow={cow}
                        />
                    );
                })}
            </div>
        </div>
    );
}
