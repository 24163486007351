import { gql } from "@apollo/client";

export const FETCH_COWS_QUERY = gql`
{
    getAllCows {
        id
        tagNo
        origin
        groups {
            currentGroupID
            currentGroupName
            history {
                id
                groupId
                periodStart
                periodEnd
            }
        }
        weights {
            id
            date
            weight
            notes
        }
        medicineHistory {
            id
            medId
            date
            if_withdrawalEnd
            batchNo
            note
        }
    }
    getGroups {
        id
        name
    }
    getFeedLogs{
  	id
	  date
    mass
    group{
      id
      name
    }
    feedType
    note
  }
}
`;

