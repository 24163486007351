import { useState } from "react";
import { Modal } from "semantic-ui-react";

// !package and helper imports
import indivCow from "../../../util/indivCowClass";

// ! type imports
import { groupType } from "../../../util/Types/GroupType";
import AniEditCowTagInput from "./AniEditCowTagInput";
import AniEditGroupInput from "./AniEditGroupInput";
import AniEditOriginInput from "./AniEditOriginInput";
import AniEditWeightsComponent from "./AniEditWeightsComponent";
import AniEditMedsComponent from "./AniEditMedsComponent";

type AniEditModalrops = {
    cow: indivCow;
    groups: Array<groupType>;
};

// type ChangedStateProps = {
//     cowTag: boolean;
//     groupId?: boolean;
//     origin?: boolean;
// };
export default function AniEditModal({ cow, groups }: AniEditModalrops) {
    const [open, setOpen] = useState(false);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <button className="bg-teal-400  hover:bg-teal-600 p-3 rounded ml-3">
                    More
                </button>
            }
        >
            <Modal.Header className="bg-gray-100">More Info/Edit</Modal.Header>

            <Modal.Content className="h-vh60 bg-gray-100 sm:h-vh70 overflow-y-scroll">
                <div className="grid gap-3 w-fit Class Properties auto-cols-fr max-w-full  ">
                    <AniEditCowTagInput cow={cow} />
                    <AniEditGroupInput cow={cow} groups={groups} />
                    <AniEditOriginInput cow={cow} />

                    <div className="col-start-1 col-end-2  bg-gray-300  text-black rounded p-3 ">
                        <h2 className="p-0 m-0  max-w-full">Avg/Life:</h2>
                        <h3 className="p-0 m-0  max-w-full">
                            {cow.getLifetimeAvg()}
                        </h3>
                    </div>
                    <div className=" col-start-2 col-end-3 bg-gray-300 text-black rounded p-3">
                        <h2 className="m-0 p-0">With</h2>
                        <h3 className="text-red-600 m-0 p-0">
                            {cow.getLatestWith()}
                        </h3>
                    </div>
                    <div className=" col-start-3 col-end-4  bg-gray-300  text-black rounded p-3">
                        <h2 className="inline max-w-full">LastWeight: </h2>
                        <h3 className="m-0 p-0">{cow.getLW()}</h3>
                    </div>
                    <AniEditWeightsComponent cow={cow} />
                    <AniEditMedsComponent cow={cow} />
                </div>
            </Modal.Content>

            <Modal.Actions className="bg-gray-100">
                <button
                    className="bg-gray-400  hover:bg-gray-300 p-3 rounded ml-3"
                    onClick={() => setOpen(false)}
                >
                    close
                </button>
            </Modal.Actions>
        </Modal>
    );
}
