// package imports
import React, { useState } from "react";

// context imports

// component and helper imports
import RenderGroupOptions from "../../util/helpers/RenderGroupOptions";
import ButtonTeal from "../../components/InputComponents/ButtonTeal";
import groupCollection from "../../util/groupCollectionClass";

type ArchiveGroupTabProps = {
    groups: groupCollection;
};
export default function ArchiveGroupTab({ groups }: ArchiveGroupTabProps) {
    const [selectedGroup, setSelectedGroup] = useState("unassigned");

    return (
        <div className="p-3 bg-gray-100 rounded text-black">
            <div className="flex flex-col items-center justify-center">
                <h1> Archive tab</h1>
                <h3>Select group to archive</h3>
                <select
                    name="currentGroupId"
                    className="w-32 border-2 border-gray-200 p-2 rounded"
                    value={selectedGroup}
                    onChange={(e) => {
                        setSelectedGroup(e.target.value);
                    }}
                >
                    <option value="all">all Groups</option>
                    <option value="unassigned">Unassigned</option>
                    <RenderGroupOptions groups={groups.getGroups()} />
                </select>

                <ButtonTeal
                    content="Archive Group"
                    classNameAdd="mt-3"
                    onClick={() => {
                        const confirmed = window.confirm("are you sure?");
                        if (confirmed) {
                            groups.archiveGroup(selectedGroup);
                        }
                        console.log(confirmed);
                    }}
                />
            </div>
        </div>
    );
}
