import React, { useState } from "react";

import ConfirmDelete from "../../../components/ConfirmDelete";
import indivCow from "../../../util/indivCowClass";
import { CowMedEntry } from "../../../util/Types/CowmedEntry";
import { medType } from "../../../util/Types/MedType";

type Props = {
    cow: indivCow;
    cowMedEntry?: CowMedEntry;
    meds: Array<medType>;
};
// meds: medType;
export default function EditMeds({
    cowMedEntry,
    cow,
    meds,
}: Props): JSX.Element {
    const [changed, setChanged] = useState(false);
    const [values, setValues] = useState({
        date: cowMedEntry?.date
            ? cowMedEntry.date.slice(0, 10)
            : new Date().toISOString().slice(0, 10),
        if_withdrawalEnd: cowMedEntry?.if_withdrawalEnd
            ? cowMedEntry.if_withdrawalEnd.slice(0, 10)
            : "",
        medId: cowMedEntry?.medId ? cowMedEntry.medId : "unassigned",
        note: cowMedEntry?.note ? cowMedEntry.note : "",
        batchNo: cowMedEntry?.batchNo ? cowMedEntry.batchNo : "",
    });
    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        setChanged(true);
    };

    return (
        <div
            // key={cowMedEntry.id}
            className=" border-2 rounded bg-gray-300 m-3 p-5 text-center w-min"
        >
            <select
                name="medId"
                className=" border-2 border-gray-200 rounded p-2 focus:outline-none focus:border-2 focus:border-teal-300"
                value={values.medId}
                onChange={(e) => {
                    onChange(e);
                    setChanged(true);
                }}
            >
                {/* <option value="unassigned">Unassigned</option> */}
                {meds.map((med) => {
                    return (
                        <option key={med.id} value={med.id}>
                            {med.medName}
                        </option>
                    );
                })}
            </select>
            <input
                type="date"
                name="date"
                value={values.date}
                className=" border-2 border-gray-200 bg-white p-3 w-44 rounded mt-3 focus:outline-none focus:border-2 focus:border-teal-300"
                onChange={(e) => {
                    onChange(e);
                    setChanged(true);
                }}
            />
            <input
                type="date"
                defaultValue={values.if_withdrawalEnd}
                className="text-red-400 border-2 border-gray-200 bg-white p-3 rounded w-44 mt-3 focus:outline-none focus:border-2 focus:border-teal-300"
                readOnly
            />
            <input
                type="text"
                name="batchNo"
                placeholder="Batch No"
                value={values.batchNo}
                className=" border-2 border-gray-200 bg-white p-3 w-44 rounded mt-3 focus:outline-none focus:border-2 focus:border-teal-300"
                onChange={(e) => {
                    onChange(e);
                    setChanged(true);
                }}
            />
            <input
                type="text"
                value={values.note}
                name="note"
                placeholder="Note"
                className=" border-2 border-gray-200 bg-white p-3 w-44 rounded mt-3 focus:outline-none focus:border-2 focus:border-teal-300"
                onChange={(e) => {
                    onChange(e);
                    setChanged(true);
                }}
            />
            <div className="mt-3">
                <button
                    className={`bg-gray-400  hover:bg-gray-300 text-black p-3 rounded mr-1 ${
                        changed || !cowMedEntry
                            ? "bg-teal-400  hover:bg-teal-600 "
                            : ""
                    }`}
                    onClick={() => {
                        if (!cowMedEntry) {
                            // addMed();
                            cow.addMed(
                                values.medId,
                                values.date,
                                values.batchNo,
                                values.note
                            );
                        } else {
                            // updateMed();
                            cow.updateMed(
                                cowMedEntry.id,
                                values.medId,
                                values.date,
                                values.batchNo,
                                values.note
                            );
                        }
                    }}
                >
                    {!cowMedEntry ? "Add" : "Save"}
                </button>

                {cowMedEntry ? (
                    <ConfirmDelete
                        deleteFunc={() => {
                            cow.deleteMed(cowMedEntry.id);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
}
