import React from "react";
import ButtonTeal from "../InputComponents/ButtonTeal";

type PaginationNavProps = {
    pageUpHandler(): void;
    pageDownHandler(): void;
    getPageCount(): number;
    getCurrentPage(): number;
};
export default function PagnationNav({
    pageDownHandler,
    pageUpHandler,
    getPageCount,
    getCurrentPage,
}: PaginationNavProps) {
    return (
        <div className=" p-3 rounded text-black flex w-full justify-center items-center ">
            <div className=" p-3 rounded flex w-1/2 justify-evenly items-center bg-gray-100 drop-shadow">
                <ButtonTeal
                    content="Prev"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                        pageDownHandler();
                        // setCurrentPage(pageDown(currentPage, pageArray));
                    }}
                    classNameAdd=""
                />
                <p className="p-0 m-0">
                    {" "}
                    {getCurrentPage()}...{getPageCount()}
                </p>
                <ButtonTeal
                    content="next"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                        // setCurrentPage(pageUp(currentPage, pageArray));
                        pageUpHandler();
                    }}
                    classNameAdd={""}
                />
            </div>
        </div>
    );
}
