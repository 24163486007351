import { makePageArray } from "../../../util/helpers/paginationHelpers";
import indivCow from "../../../util/indivCowClass";
import filterHelper from "./filterHelper";
import sortHelper from "./sortHelper";

const func = 
 (
    StartData: Array<indivCow>,
    filterValue: String,
    sortValue: String,
    flipVal: number
): any[][] => {

    const filtered = filterHelper(StartData, filterValue);

    const sorted = sortHelper(filtered, sortValue, flipVal);
    return makePageArray(sorted, 20)

}
export default func