import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import indivCow from "../../../util/indivCowClass";
import ValidateTagNo from "../../../util/validators/ValidateTagNo";

type Props = { cow: indivCow };
export default function AniEditCowTagInput({ cow }: Props) {
    const [pageState, setPageState] = useState({
        changed: false,
        tagNo: cow.getTag(),
    });
    return (
        <div className=" bg-gray-300  text-black rounded p-3 col-start-1 col-end-2 flex flex-col items-center">
            <h2 className="inline max-w-full">TagNo:</h2>
            <input
                type="text"
                name="cowTag"
                defaultValue={cow.getTag()}
                className=" border-2 border-gray-200 bg-white max-w-full p-3 w-24 rounded ml-3"
                onChange={(e) => {
                    setPageState({ changed: true, tagNo: e.target.value });
                }}
            />

            <button
                className={`bg-gray-400 mt-2 hover:bg-gray-300 text-black p-3 rounded ml-3 ${
                    pageState.changed ? "bg-teal-400  hover:bg-teal-600 " : ""
                }`}
                onClick={() => {
                    if (!pageState.changed) {
                        return;
                    }
                    const validateRes = ValidateTagNo(pageState.tagNo);
                    if (!validateRes.valid) {
                        console.log(`Errors: ${validateRes.errors}`);
                        return;
                    }
                    // updateTag()
                    cow.updateTag(pageState.tagNo);
                }}
            >
                <FaSave />
            </button>
        </div>
    );
}
