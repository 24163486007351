import indivCow from './indivCowClass'
import { client } from "../ApolloProvider"
import { gql } from '@apollo/client'



export default class cowCollection {
    constructor(cowObjArr) {
        this.cows = cowObjArr.map((cow) => {
            return new indivCow(cow)
        })
    }

    getCows() {
        return this.cows;
    }
    getByGroup(groupId) {
        const arr = this.cows.filter((cow => cow.getCGId() === groupId))
        return arr
    }
    getGroupTotalWeight(groupId) {
        const cows = this.getByGroup(groupId)
        let totalWeight = 0
        cows.forEach(cow => totalWeight += cow.getLW() !== "N/A" ? cow.getLW() : 0)
        return totalWeight;
    }
    getCowCount() {
        return this.cows.length
    }
    getTotalWeight() {
        let totalWeight = 0
        this.cows.forEach((cow) => totalWeight += cow.getLW() !== "N/A" ? cow.getLW() : 0)
        return totalWeight;
    }
    getWeighDates(groupId) {
        let dates = new Set([])
        let cowsToSearch = this.cows
        if (groupId) {
            cowsToSearch = this.getByGroup(groupId)
        }
        cowsToSearch.forEach((cow) => {
            cow.getWeights().forEach((weight) => {
                dates.add(weight.date)
            })
        })
        return [...dates]
    }
    getWeightsGandD(date, groupId) {
        let weights = this.getByGroup(groupId).map((cow) => {
            const weight = cow.getWeightByDate(date)
            // console.log(weight)
            return weight;
        });

        return weights;

    }
    getGroupTWByDate(date, groupId) {
        let totalWeight = 0
        const cows = this.getByGroup(groupId)
        cows.forEach((cow) => {
            cow.getWeights().forEach((weight) => {
                if (weight.date === date) {
                    totalWeight += weight.weight
                }
            })
        })
        return totalWeight;
    }
    getGroupQty(groupId) {
        const cows = this.getByGroup(groupId)
        return cows.length
    }

    addMedsByGroup(groupId, medId, date, batchNo, note) {
        const cows = this.getByGroup(groupId);
        cows.forEach((cow) => {
            cow.addMed(medId, date, batchNo, note)
        })
    }

    removeCow(id) {
        this.cows = this.cows.filter((cow) =>
            cow.getId() !== id
        )
    }

    deleteCow(id) {
        this.cows.forEach((cow) => {
            if (cow.getId() === id) {
                cow.delete()
                this.removeCow(id)
            }
        })
    }

    async addCow({ cowTag, startWeight, startDate, currentGroupID, origin, groupPeriodStart }) {

        const ADDQUERY = gql`
        mutation{
    createCow(CreateCowInput: {cowTag:"${cowTag}", startWeight:${startWeight ? parseInt(startWeight) : 0}, startDate:"${startDate ? startDate : ""}", groupPeriodStart:"${groupPeriodStart ? groupPeriodStart : ""}",currentGroupID: "${currentGroupID ? currentGroupID : ""}",  origin:"${origin ? origin : ""}"}){
        ${STDFIELDS}
    }
    }
    `;
        let newCow;
        await client.mutate({ mutation: ADDQUERY }).then((data) => {
            console.log(data.data.createCow)
            newCow = new indivCow({ ...data.data.createCow })
            this.cows.push(newCow)
            return newCow
        }).catch((err) => {
            console.log(err)
            return false;

        })

    }
}
const STDFIELDS = ` id
tagNo
origin
groups{
    currentGroupID
    currentGroupName
    currentPeriodStart
        history{
            id
            groupId
            periodStart
        periodEnd
        }
}
weights{
    id
    date
    weight
    notes
}
medicineHistory{
    id
    medId
    date
    if_withdrawalEnd
    batchNo
    note
}`
