// import { client } from "../ApolloProvider"
// import { gql } from '@apollo/client'

export default class group {
    constructor(groupObj) {
        this.id = groupObj.id
        this.name = groupObj.name
    }
    getGroup() {
        return { id: this.id, name: this.name }
    }
    getName() {
        return this.name
    }
    getId() {
        return this.id
    }
    update(groupName) {
        this.name = groupName
    }



}