import React, { useState } from "react";

import ConfirmDelete from "../../../components/ConfirmDelete";
export default function EditWeights({ weight, cow }: any) {
    const [changed, setChanged] = useState(false);

    const [values, setValues] = useState({
        date: weight.date ? weight.date : new Date().toISOString().slice(0, 10),
        weight: weight.weight ? weight.weight : 0,
        note: weight.notes ? weight.notes : "",
    });
    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        setChanged(true);
    };

    return (
        <div className=" rounded bg-gray-300 m-3 p-5 text-center  w-min">
            <h3 className="p-0 m-0">Date:</h3>
            <input
                type="date"
                name="date"
                onChange={onChange}
                value={values.date.slice(0, 10)}
                className=" border-2 border-gray-200 bg-white p-3 rounded w-44   focus:outline-none focus:border-2 focus:border-teal-300"
            />
            <h3 className="p-0 m-0">Weight:</h3>
            <input
                type="number"
                name="weight"
                onChange={onChange}
                placeholder="weight"
                value={values.weight}
                className=" border-2 border-gray-200 bg-white p-3 w-44 rounded  focus:outline-none focus:border-2 focus:border-teal-300"
            />
            <input
                type="text"
                name="note"
                onChange={onChange}
                placeholder="Note"
                value={values.note}
                className=" border-2 border-gray-200 bg-white p-3 w-44 rounded  mt-3 focus:outline-none focus:border-2 focus:border-teal-300"
            />
            <div className="m-0 mt-3 flex items-center justify-center">
                <button
                    className={`bg-gray-400  hover:bg-gray-300 text-black p-3 rounded mr-1 ${
                        changed || weight.addFlag
                            ? "bg-teal-400  hover:bg-teal-600 "
                            : ""
                    }`}
                    onClick={() => {
                        if (weight.addFlag) {
                            // addWeight();
                            cow.addWeight(
                                values.date,
                                values.weight,
                                values.note
                            );
                        } else {
                            cow.updateWeight(
                                weight.id,
                                values.date,
                                values.weight,
                                values.note
                            );
                            setChanged(false);
                            // updateWeight();
                        }
                    }}
                >
                    {weight.addFlag ? "add" : "Save"}
                </button>
                {/* <Button
                    icon={weight.addFlag ? "plus" : "save"}
                    content={weight.addFlag ? "add" : "Save"}
                    className=""
                    color={changed | weight.addFlag ? "green" : "grey"}
                    onClick={() => {
                        if (weight.addFlag) {
                            // addWeight();
                            cow.addWeight(values.date, values.weight, values.note)
                        } else {
                            cow.updateWeight(weight.id, values.date, values.weight, values.note)
                            setChanged(false);
                            // updateWeight();
                        }
                    }}
                /> */}
                {!weight.addFlag ? (
                    <ConfirmDelete
                        deleteFunc={() => {
                            cow.deleteWeight(weight.id);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
}
