import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Loading from "../../../components/Loading";
import PaginatedListView from "../../../components/Pagination/PaginatedListView";
import {
    makePageArray,
    pageDown,
    pageUp,
} from "../../../util/helpers/paginationHelpers";
import { RecipeType } from "../../../util/Types/RecipeType";
import AddRecipeInput from "./AddRecipeInput";
import RecipeCard from "./RecipeCard";
import { FETCH_ALL_RECIPES_QUERY } from "./RecipePageGQLQueries";
import SingleRecipeView from "./SingleRecipeView";

type RecipePageState = {
    fetched: boolean;
    recipes: RecipeType[];

    currentPage: number;
    isViewing: boolean;
    currentViewingRecipe?: RecipeType;
};
export default function RecipePage() {
    const [pageState, setPageState] = useState<RecipePageState>({
        fetched: false,
        recipes: [],
        currentPage: 0,
        isViewing: false,
    });

    const { error } = useQuery(FETCH_ALL_RECIPES_QUERY, {
        onCompleted: (data) => {
            console.log(data.getRecipes);

            setPageState({
                ...pageState,

                fetched: true,
                recipes: data.getRecipes,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    console.log(pageState.recipes);
    let paginationArr = makePageArray(pageState.recipes, 10);
    let DisplayItems = paginationArr[pageState.currentPage].map((recipe) => {
        return (
            <RecipeCard
                key={recipe.id}
                recipe={recipe}
                viewRecipeCallBack={() => {
                    setPageState({
                        ...pageState,
                        isViewing: true,
                        currentViewingRecipe: recipe,
                    });
                }}
                deleteCallBack={function (id: string): void {
                    const items = [...pageState.recipes].filter(
                        (r) => r.id !== id
                    );

                    setPageState({
                        ...pageState,
                        recipes: items,
                    });
                }}
            />
        );
    });
    if (pageState.isViewing && pageState.currentViewingRecipe) {
        return (
            <SingleRecipeView
                recipe={pageState.currentViewingRecipe}
                endViewIngCallBack={function (): void {
                    setPageState({
                        ...pageState,
                        isViewing: false,
                        currentViewingRecipe: undefined,
                    });
                }}
            />
        );
    }
    return (
        <div className="p-3 bg-gray-300 flex flex-col items-center  ">
            <AddRecipeInput
                addRecipeCallback={function (recipe: RecipeType): void {
                    setPageState({
                        ...pageState,
                        recipes: [recipe, ...pageState.recipes],
                    });
                }}
            />
            <PaginatedListView
                DisplayItems={DisplayItems}
                pageUpHandler={function (): void {
                    const pageNo = pageUp(pageState.currentPage, paginationArr);
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                pageDownHandler={function (): void {
                    const pageNo = pageDown(pageState.currentPage);
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                getPageCount={function (): number {
                    return paginationArr ? paginationArr.length : 0;
                }}
                getCurrentPage={function (): number {
                    return pageState.currentPage + 1;
                }}
            />
        </div>
    );
}
