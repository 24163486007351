import React, { useState } from "react";
import { client } from "../../../ApolloProvider";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import { RecipeType } from "../../../util/Types/RecipeType";
import { GET_CREATE_RECIPE_QUERY } from "./RecipePageGQLQueries";

type PageProps = {
    addRecipeCallback: (recipe: RecipeType) => void;
};

type PageState = {
    recipeName: string;
};
export default function AddRecipeInput({ addRecipeCallback }: PageProps) {
    const [pageState, setPageState] = useState<PageState>({
        recipeName: "",
    });

    return (
        <div>
            <input
                type="text"
                className="p-3 m-2 rounded"
                placeholder="Name"
                value={pageState.recipeName}
                onChange={(e: any) => {
                    setPageState({ ...pageState, recipeName: e.target.value });
                }}
            />
            <ButtonTeal
                content={"Add"}
                onClick={function (): void {
                    client
                        .mutate({
                            mutation: GET_CREATE_RECIPE_QUERY(
                                pageState.recipeName
                            ),
                        })
                        .then((data) => {
                            console.log(data);
                            alert("Success");
                            addRecipeCallback(data.data.createRecipe);
                        });
                }}
                classNameAdd={""}
            />
        </div>
    );
}
