// import { Menu } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { FaChevronRight, FaBars } from "react-icons/fa";

// import GetWorkContext from "./subComponents/GetWorkContext";
export default function MenuComp() {
    const pathname = window.location.pathname;
    const context = useContext(AuthContext);
    const [state, setState] = useState({ activeItem: pathname, display: window.innerWidth > 768 ? true : false });
    const [userDisplay, setUserDisplay] = useState(false);
    if (context.user) {
        return (
            <nav className="flex  w-full  flex-row bg-gray-100 z-50 fixed ">
                {/* <GetWorkContext /> */}
                {state.display ? (
                    <div className="w-full justify-center items-center bg-gray-100 flex flex-col text-black md:flex-row p-3 ">
                        <Link
                            to="/"
                            className={
                                state.activeItem === "/"
                                    ? "rounded p-0 px-6 text-teal-500 "
                                    : "rounded text-black p-0 px-6 hover:text-teal-500"
                            }
                            onClick={() => {
                                setState({ ...state, activeItem: "/", display: window.innerWidth > 768 ? true : !state.display });
                            }}
                        >
                            <div>
                                <h1 className=" text-4xl sm:text-2xl">Home</h1>
                            </div>
                        </Link>

                        <div className="flex flex-col justify-center md:flex-row w-full items-center">
                            <Link
                                to="/animals"
                                className={
                                    state.activeItem === "/animals"
                                        ? "rounded  p-0 px-6 text-teal-500 "
                                        : "rounded text-black p-0 px-6 hover:text-teal-500 "
                                }
                                onClick={() => {
                                    setState({ ...state, activeItem: "/animals", display: window.innerWidth > 768 ? true : !state.display });
                                }}
                            >
                                <div>
                                    <h1 className=" text-4xl  sm:text-2xl">
                                        Animals
                                    </h1>
                                </div>
                            </Link>
                            <Link
                                to="/feed"
                                className={
                                    state.activeItem === "/feed"
                                        ? "rounded p-0 px-6 text-teal-500 "
                                        : "rounded text-black p-0 px-6 hover:text-teal-500 "
                                }
                                onClick={() => {
                                    setState({ ...state, activeItem: "/feed", display: window.innerWidth > 768 ? true : !state.display });
                                }}
                            >
                                <div>
                                    <h1 className=" text-4xl  sm:text-2xl">
                                        Feed
                                    </h1>
                                </div>
                            </Link>

                            <Link
                                to="/reporting"
                                className={
                                    state.activeItem === "/reporting"
                                        ? "rounded  p-0 px-6 text-teal-500"
                                        : "rounded text-black p-0 px-6 hover:text-teal-500"
                                }
                                onClick={() => {
                                    setState({ ...state, activeItem: "/reporting", display: window.innerWidth > 768 ? true : !state.display });
                                }}
                            >
                                <div>
                                    <h1 className=" text-4xl sm:text-2xl">
                                        Reporting
                                    </h1>
                                </div>
                            </Link>

                            <Link
                                to="/Admin"
                                className={
                                    state.activeItem === "/admin"
                                        ? "rounded  p-0 px-6 text-teal-500 "
                                        : "rounded text-black p-0 px-6 hover:text-teal-500 "
                                }
                                onClick={() => {
                                    setState({ ...state, activeItem: "/admin", display: window.innerWidth > 768 ? true : !state.display });
                                }}
                            >
                                <div>
                                    <h1 className=" text-4xl sm:text-2xl">
                                        Admin
                                    </h1>
                                </div>
                            </Link>
                            <Link
                                to="/stock"
                                className={
                                    state.activeItem === "/stock"
                                        ? "rounded  p-0 px-6 text-teal-500 "
                                        : "rounded text-black p-0 px-6 hover:text-teal-500 "
                                }
                                onClick={() => {
                                    setState({ ...state, activeItem: "/stock", display: window.innerWidth > 768 ? true : !state.display });
                                }}
                            >
                                <div>
                                    <h1 className=" text-4xl sm:text-2xl">
                                        Stock
                                    </h1>
                                </div>
                            </Link>
                            <div className="text-black md:ml-auto text-center flex items-center justify-between ">

                                <button
                                    className="bg-teal-400  hover:bg-teal-600 p-3 rounded" onClick={() => {
                                        setUserDisplay(!userDisplay);
                                    }}>
                                    {context.user.username}<FaChevronRight className="inline h-5 w-5 " />
                                </button>

                                {userDisplay ? (

                                    <button className=" ml-10 bg-red-600 hover:bg-red-700 p-3 rounded text-white"
                                        onClick={context.logout}>Log Out</button>

                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="p-5 text-black hover:text-teal-400 h-auto ml-auto">
                    <FaBars
                        className="w-5 mb-auto h-5 sm:h-8 sm:w-8 z-20"
                        onClick={() => {
                            // setDisplay(!display);
                            setState({ ...state, display: !state.display })
                        }}
                    />
                </div>
            </nav>
        );
    } else
        return (
            <nav className="w-full bg-gray-100 flex text-black p-3 fixed">
                <Link
                    to="/"
                    className="text-black p-0 px-2  hover:text-teal-400"
                >
                    <div>
                        <h1>Home</h1>
                    </div>
                </Link>
                <Link
                    to="/login"
                    className="text-black p-0 px-2  hover:text-teal-500  ml-auto"
                >
                    <div>
                        <h1>Log In</h1>
                    </div>
                </Link>
            </nav>
        );
}
