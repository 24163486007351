import { useState } from "react";
import { Menu } from "semantic-ui-react";

import cowCollection from "../../util/cowCollectionClass";
import groupCollection from "../../util/groupCollectionClass";
import RenderGroupOptions from "../../util/helpers/RenderGroupOptions";
import MassCapMeds from "./editSubComponents/MassCapMeds";

type AddMedsTabProps = {
    cows: cowCollection;
    groups: groupCollection;
    meds: any;
};
export default function AddMedTab({ cows, groups, meds }: AddMedsTabProps) {
    const [currentGroup, setCurrentGroup] = useState("all");

    const [filterState, setFilterState] = useState({
        type: "group",
        value: "all",
    });

    let cowArr = cows.getCows().map((cow: any) => {
        if (filterState.value !== "all") {
            if (filterState.type === "group") {
                return {
                    cow: cow,
                    render: cow
                        .getCGId()
                        .toUpperCase()
                        .includes(filterState.value.toUpperCase()),
                };
            } else if (filterState.type === "search") {
                return {
                    cow: cow,
                    render: cow
                        .getTag()
                        .toUpperCase()
                        .includes(filterState.value.toUpperCase()),
                };
            } else {
                return { cow: cow, render: true };
            }
        } else {
            return { cow: cow, render: true };
        }
    });

    return (
        <div className="container">
            <Menu className="overflow-y-scroll bg-gray-300">
                <Menu.Item className="p-2">
                    <div className="text-center m-0">
                        <h4 className="my-0 mr-2">Search</h4>
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-32 border-2 border-gray-200 p-2 rounded"
                            onChange={(e) => {
                                setFilterState({
                                    type: "search",
                                    value: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Menu.Item>

                <Menu.Item posion="right " className="p-2">
                    <div className="text-center p-1">
                        <h4 className="my-0 mr-2">Filter</h4>
                        <select
                            name="currentGroupId"
                            className="w-32 border-2 border-gray-200 p-2 rounded"
                            value={currentGroup}
                            onChange={(e) => {
                                setCurrentGroup(e.target.value);
                                setFilterState({
                                    type: "group",
                                    value: e.target.value,
                                });
                            }}
                        >
                            <option value="all">all Groups</option>

                            <RenderGroupOptions groups={groups.getGroups()} />
                        </select>
                    </div>
                </Menu.Item>
            </Menu>

            <div
                className={`"flex flex-col bg-gray-100 rounded  overflow-y-scroll h-vh70 p-3`}
            >
                {cowArr.map((cow: any) => {
                    return (
                        <MassCapMeds
                            key={cow.cow.getId()}
                            cowObj={cow}
                            meds={meds}
                        />
                    );
                })}
            </div>
        </div>
    );
}
