// package imports
import React, { ReactElement, useState } from "react";
import { useQuery } from "@apollo/client";

//! component and helper imports
// import AddCows from "./subComponents/adminpage/AddCows";
import AddCowsTab from "./AddCowsTab";
// import MassCapture from "./subComponents/adminpage/MassCapture";
// import ManageMeds from "./subComponents/adminpage/ManageMeds";
import WeightsTab from "./WeightsTab";
// import Groups from "./subComponents/adminpage/Groups";
import ManageGroups from "./ManageGroups";
import MoveGroupsTab from "./MoveGroupsTab";
import AddMedTab from "./AddMedTab";
import ManageMedsTab from "./ManageMedsTab";
import ArchiveGroupTab from "./ArchiveGroupTab";

import { FETCH_ALL_DATA_QUERY } from "./Page helpers and config/pageGQLQueries";

import { medType } from "../../util/Types/MedType";
import feedCollection from "../../util/feedCollection";

import Loading from "../../components/Loading";
import cowCollection from "../../util/cowCollectionClass";
import groupCollection from "../../util/groupCollectionClass";
// TODO: check all sub pages and fix "Any" typings used to get page running again
type PageState = {
    openTab: string;
    fetched: Boolean;
    cows?: cowCollection;
    groups?: groupCollection;
    meds?: Array<medType>;
    feeds?: feedCollection;
};
// TODO: Convert to using TabNaviagte Component
export default function Admin() {
    const [pageState, setPageState] = useState<PageState>({
        openTab: "MAIN",
        fetched: false,
    });

    const { error } = useQuery(FETCH_ALL_DATA_QUERY, {
        onCompleted: (data) => {
            setPageState({
                ...pageState,
                fetched: true,
                cows: new cowCollection(data.getAllCows),
                groups: new groupCollection(data.getGroups),
                meds: data.getMeds,
                feeds: new feedCollection(data.getFeeds),
            });
        },
    });
    if (error) {
        return <>Error Fetching data</>;
    }
    if (!pageState.fetched) {
        return <Loading />;
    }

    console.log(pageState);
    let tabs = [
        { nav: "MAIN", buttonText: "Main" },
        { nav: "ADD", buttonText: "Add Cows" },
        { nav: "WEIGHTS", buttonText: "Weights" },
        { nav: "MOVEGROUPS", buttonText: "Move Cows" },
        { nav: "ADDCOWMED", buttonText: "Add Cow Med" },
        { nav: "MANAGEGROUP", buttonText: "Manage Groups" },
        { nav: "MANAGEMED", buttonText: "Manage Meds" },
        { nav: "ARCHIVEGROUPS", buttonText: "Archive Groups" },
        // { nav: "MANAGEFEED", buttonText: "Manage Feeds" },
        // { nav: "FEEDLOCATIONS", buttonText: "Feed Distribution" },
    ];

    const butttonCssString = (tabName: string) => {
        return `${
            pageState.openTab === tabName ? "bg-teal-400" : "bg-gray-400"
        }  hover:bg-teal-600 p-3 rounded mr-1`;
    };
    const getTabElement = () => {
        let tab: ReactElement = <></>;
        if (!pageState.cows || !pageState.groups) {
            return <>Error</>;
        }
        switch (pageState.openTab) {
            case "MAIN":
                tab = <></>;
                break;
            case "ADD":
                tab = (
                    <AddCowsTab
                        cows={pageState.cows}
                        groups={pageState.groups}
                    />
                );
                break;
            case "WEIGHTS":
                tab = (
                    <WeightsTab
                        cows={pageState.cows}
                        groups={pageState.groups}
                    />
                );
                break;
            case "MOVEGROUPS":
                tab = tab = (
                    <MoveGroupsTab
                        cows={pageState.cows}
                        groups={pageState.groups}
                    />
                );
                break;
            case "ADDCOWMED":
                tab = (
                    <AddMedTab
                        cows={pageState.cows}
                        groups={pageState.groups}
                        meds={pageState.meds}
                    />
                );
                break;
            case "MANAGEGROUP":
                tab = (
                    <ManageGroups
                        cows={pageState.cows}
                        groups={pageState.groups}
                    />
                );
                break;
            case "MANAGEMED":
                tab = (
                    <ManageMedsTab
                        meds={pageState.meds}
                        addMedToState={function (med) {
                            if (!pageState.meds) {
                                return;
                            }
                            setPageState({
                                ...pageState,
                                meds: [...pageState.meds, med],
                            });
                        }}
                        updateMedInState={function (med) {
                            if (!pageState.meds) {
                                return;
                            }
                            let tempMedArr = pageState.meds;
                            const medIndex = tempMedArr.findIndex(
                                (medc) => medc.id === med.id
                            );
                            if (medIndex !== -1) {
                                tempMedArr[medIndex] = med;
                            }
                            setPageState({
                                ...pageState,
                                meds: tempMedArr,
                            });
                            throw new Error("Function not implemented.");
                        }}
                    />
                );
                break;
            case "ARCHIVEGROUPS":
                tab = <ArchiveGroupTab groups={pageState.groups} />;
                break;
            // case "FEEDLOCATIONS":
            //     tab = <FeedLocations />;
            //     break;
            // case "MANAGEFEED":
            //     tab = <ManageFeeds />;
            //     break;
            default:
                <></>;
        }
        return tab;
    };
    // to help cut down on lines and help with code duplication i will render a navigation button
    // For each name in this list instead of manually declaring each button individually as was originally done
    return (
        <div className="container mt-4">
            <div className="flex bg-gray-300 p-3 overflow-x-scroll w-full rounded">
                {tabs.map((pageInfo) => {
                    return (
                        <button
                            className={butttonCssString(pageInfo.nav)}
                            key={pageInfo.nav}
                            onClick={() => {
                                setPageState({
                                    ...pageState,
                                    openTab: pageInfo.nav,
                                });
                            }}
                        >
                            {pageInfo.buttonText}
                        </button>
                    );
                })}
            </div>
            <div className="bg-gray-100 rounded mt-0">{getTabElement()}</div>
        </div>
    );
}
