import React from 'react'
import group from '../groupClass';

type GroupProps = {
    groups: Array<group>
}
export default function RenderGroupOptions({ groups }: GroupProps): JSX.Element {
    return<>
     {groups.map((group: group) => {  
             return <option value={group.id} key={group.id}>
                {group.name}
            </option>
    })}
    </>
    
}
