import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import ButtonTeal from "../../../../components/InputComponents/ButtonTeal";
import { StockItem } from "../../../../util/Types/StockItemType";
import Loading from "../../../../components/Loading";
import { GET_FETCH_SM_QUERY } from "../StockItemPageGQLQueries";
import AddSMInput from "./AddSMInput";
import {
    makePageArray,
    pageDown,
    pageUp,
} from "../../../../util/helpers/paginationHelpers";
import PaginatedListView from "../../../../components/Pagination/PaginatedListView";
import SmCard from "./SmCard";

type SingleSiViewProps = {
    stockItem: StockItem;
    endViewIngCallBack: () => void;
};
type SingleSiViewState = {
    fetched: boolean;
    stockMovements: any[];
    currentPage: number;
};
export default function SingleSiView({
    stockItem,
    endViewIngCallBack,
}: SingleSiViewProps) {
    const [pageState, setPageState] = useState<SingleSiViewState>({
        fetched: false,
        stockMovements: [],
        currentPage: 0,
    });

    const { error } = useQuery(GET_FETCH_SM_QUERY(stockItem.id), {
        onCompleted: (data) => {
            console.log(data.getAllStockMovementsForSi);

            setPageState({
                ...pageState,

                fetched: true,
                stockMovements: data.getAllStockMovementsForSi,
                // stockItems: data.getStockItems,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    const paginationArr = makePageArray(pageState.stockMovements, 10);
    let DisplayItems = paginationArr[pageState.currentPage].map((sm) => {
        return (
            <SmCard
                key={sm.id}
                stockMovement={sm}
                deleteCallBack={(id) => {
                    setPageState({
                        ...pageState,
                        stockMovements: [...pageState.stockMovements].filter(
                            (item) => item.id !== id
                        ),
                    });
                }}
            />
        );
    });

    return (
        <div className="p-3 bg-gray-300 flex flex-col justify-evenly items-center">
            <div className="w-full flex justify-between items-center ">
                <ButtonTeal
                    content={"Back"}
                    onClick={function (e: any): void {
                        endViewIngCallBack();
                    }}
                    classNameAdd={"inline flex-start"}
                />
                <h3 className=" m-0 p-0">{stockItem.stockItemName}</h3>
            </div>
            <AddSMInput
                stockItem={stockItem}
                addSmCallback={(sm) => {
                    const smArr = [...pageState.stockMovements];
                    smArr.push(sm);
                    setPageState({ ...pageState, stockMovements: smArr });
                }}
            />

            <PaginatedListView
                DisplayItems={DisplayItems}
                pageUpHandler={function (): void {
                    const pageNo = pageUp(pageState.currentPage, paginationArr);
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                pageDownHandler={function (): void {
                    const pageNo = pageDown(pageState.currentPage);
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                getPageCount={function (): number {
                    return paginationArr ? paginationArr.length : 0;
                }}
                getCurrentPage={function (): number {
                    return pageState.currentPage + 1;
                }}
            />
        </div>
    );
}
