import React, { useState } from "react";
import { client } from "../../../ApolloProvider";
import ButtonTeal from "../../../components/InputComponents/ButtonTeal";
import { GET_PROCESS_RECIPE_QUERY } from "./RecipePageGQLQueries";

type PageState = {
    amount: number;
};
type PageProps = {
    recipeId: string;
};
export default function ProcessInput({ recipeId }: PageProps) {
    const [pageState, setPageState] = useState<PageState>({
        amount: 0,
    });
    return (
        <div>
            <input
                type="number"
                className="p-3 m-2 rounded"
                placeholder="ProcessingAmount "
                value={pageState.amount}
                onChange={(e) => {
                    console.log(parseFloat(e.target.value));
                    setPageState({
                        ...pageState,
                        amount: parseFloat(e.target.value),
                    });
                }}
            />
            <ButtonTeal
                content={"Process"}
                onClick={function (e: any): void {
                    client
                        .mutate({
                            mutation: GET_PROCESS_RECIPE_QUERY(
                                recipeId,
                                pageState.amount
                            ),
                        })
                        .then((data) => {
                            console.log(data);
                            alert("Success");
                        });
                }}
                classNameAdd={""}
            />
        </div>
    );
}
