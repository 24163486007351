import jsPDF from "jspdf";
import "jspdf-autotable";

export default function feedRepGenerator(logState, datesArr, groupState, uniqueGroups) {
    const doc = new jsPDF({
        orientation: "landscape",
    });

    const dateStr = datesArr[0] === datesArr[1] ? "N/A -- N/A" : `${datesArr[0].slice(0, 10)} -- ${datesArr[1].slice(0, 10)}`

    doc.text("Feed Report", 15, 10);

    const tableColumn = ["Group", "Total Feed", `Period: ${dateStr}`]

    const tableRows = []

    tableRows.push(["Total Fed:", logState.reduce((total, log) => {
        // console.log(log.mass);
        return total + log.mass;
    }, 0)])


    uniqueGroups.forEach((groupId) => {
        const logs = logState.filter((log) => log.group.id === groupId)

        const group = groupState
            .getGroups()
            .find((group) => group.getId() === groupId)

        tableRows.push([group ? group.getName() : "N/A", logs.reduce((total, log) => {
            // console.log(log.mass);
            return total + log.mass;
        }, 0)])

    })
    doc.autoTable({ head: [tableColumn], body: [...tableRows], startY: 20 });
    doc.save("FeedReport.pdf");
}