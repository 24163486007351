//! package imports
import React from "react";

export default function Loading() {
    return (
        <div className="notice bg-transparent">
            <p> Loading </p>
        </div>
    );
}
