import React from "react";
import { Button } from "semantic-ui-react";
import { useState } from "react";

import RenderGroupOptions from "../../../util/helpers/RenderGroupOptions";
import cowCollection from "../../../util/cowCollectionClass";
import groupCollection from "../../../util/groupCollectionClass";
import { groupType } from "../../../util/Types/GroupType";

type MassCapGroupsProps = {
    cows: cowCollection;
    groups: groupCollection;
    cowObj: any;
};
export default function MassCapGroups({
    cowObj,
    groups,
    cows,
}: MassCapGroupsProps) {
    const [saved, setSaved] = useState(false);

    const cow = cowObj.cow;
    const group = groups
        .getGroups()
        .find((group: groupType) => group.id === cow.getCGId());

    const [values, setValues] = useState({
        moveDate: new Date().toISOString().slice(0, 10),
        newGroupId: cow.groups.currentGroupID
            ? cow.groups.currentGroupID
            : "unassigned",
    });

    const onChange = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    if (!cowObj.render) {
        return <> </>;
    }

    return (
        <div className="bg-gray-300 rounded text-black mb-3 w-full ">
            <div className=" flex justify-evenly items-center w-full flex-col sm:flex-row p-3 px-5 ">
                <div>
                    <div className="flex p-3  sm:mr-0 mb-2 bg-gray-100 flex-col sm:flex-row">
                        <h3 className="m-0 inline-block">Tag: </h3>
                        <p> {cow.getTag()}</p>
                    </div>
                    <div className="flex p-3  sm:mr-0 mb-2 bg-gray-100 flex-col sm:flex-row">
                        <h3 className="m-0 inline-block">Current Group: </h3>
                        <p> {group ? group.name : "N/A"}</p>
                    </div>
                </div>

                <div className="flex flex-col ">
                    {/* <h4 className="m-0 p-0">moved</h4> */}
                    <input
                        name="moveDate"
                        className="p-1 w-40 rounded border-2 focus:outline-none focus:border-teal-200"
                        type="date"
                        value={values.moveDate}
                        onChange={onChange}
                    />
                    {/* <h4>moved</h4> */}
                    <select
                        name="newGroupId"
                        className="w-40  p-1  border-2 focus:outline-none focus:border-teal-200"
                        value={values.newGroupId}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    >
                        <option value="unassigned">Unassigned</option>
                        <RenderGroupOptions groups={groups.getGroups()} />
                    </select>
                </div>

                {saved ? null : (
                    <Button
                        color="green"
                        icon="save"
                        onClick={() => {
                            cow.moveGroup(values.newGroupId);
                            setSaved(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
