//! package imports
import React, { useState } from "react";
import { useQuery } from "@apollo/client";

//! component and helper imports
import AniCard from "./AniCard";
// import AniAddModal from "./subComponents/ani/AniAddModal";
// import PrintWeighSheetComponent from "./subComponents/weighSheets/PrintWeighSheetComponent";
import Loading from "../../components/Loading";
import cowCollection from "../../util/cowCollectionClass";
import { pageDown, pageUp } from "../../util/helpers/paginationHelpers";
import { groupType } from "../../util/Types/GroupType";
import PaginatedListView from "../../components/Pagination/PaginatedListView";
import filterAndSortHelper from "./pageHelpersAndConfig/filterAndSortHelper";
import { FETCH_COWS_QUERY } from "./pageHelpersAndConfig/PageGQLQueries";
import FilterMenuItem from "./FilterMenuItem";
import SortMenuItem from "./SortMenuItem";
import indivCow from "../../util/indivCowClass";

type AnimalsPageState = {
    fetched: boolean;
    cowHolder?: cowCollection;
    groups?: Array<groupType>;
    paginationArr?: Array<Array<any>>;
    currentPage: number;
    filter: filterType;
    sortState: SortStateType;
};
type filterType = { value: String };
type SortStateType = {
    param: string;
    flip: number;
};

export default function Animals() {
    const [pageState, setPageState] = useState<AnimalsPageState>({
        fetched: false,
        cowHolder: undefined,
        currentPage: 0,
        filter: { value: "" },
        sortState: { param: "tagno", flip: -1 },
    });

    const { error } = useQuery(FETCH_COWS_QUERY, {
        onCompleted: (data) => {
            console.log(data.getAllCows);
            const cowHolder = new cowCollection(data.getAllCows);
            setPageState({
                ...pageState,
                cowHolder: cowHolder,
                groups: data.getGroups,
                fetched: true,
                paginationArr: filterAndSortHelper(
                    cowHolder.getCows(),
                    "",
                    pageState.sortState.param,
                    pageState.sortState.flip
                ),

                currentPage: 0,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    if (!pageState.groups || !pageState.paginationArr) {
        return <>Error</>;
    }
    let DisplayItems = pageState.paginationArr[pageState.currentPage].map(
        (cow) => {
            const cowHolder = pageState.cowHolder;
            if (!pageState.groups || cowHolder === undefined) {
                return <>Error</>;
            }
            return (
                <AniCard
                    key={cow.getId()}
                    cow={cow}
                    groups={pageState.groups}
                    uiDeleteCowHandler={(CowID: string) => {
                        cowHolder.removeCow(CowID);
                        setPageState({
                            ...pageState,
                            paginationArr: filterAndSortHelper(
                                cowHolder.getCows(),
                                pageState.filter.value,
                                pageState.sortState.param,
                                pageState.sortState.flip
                            ),
                        });
                    }}
                />
            );
        }
    );
    return (
        <div className="container overflow-x-scroll mt-4">
            <div className=" bg-gray-100 flex flex-row justify-between px-3 py-3 text-black items-center rounded overflow-x-scroll w-full md:text-2xl text-center">
                <FilterMenuItem
                    groups={pageState.groups}
                    updateParent={(FilterValue) => {
                        setPageState({
                            ...pageState,
                            paginationArr: filterAndSortHelper(
                                pageState.cowHolder?.getCows(),
                                FilterValue,
                                pageState.sortState.param,
                                pageState.sortState.flip
                            ),
                            filter: { value: FilterValue },
                        });
                    }}
                />
                <SortMenuItem
                    updateParentSort={(sortVal: string) => {
                        setPageState({
                            ...pageState,
                            paginationArr: filterAndSortHelper(
                                pageState.cowHolder?.getCows(),
                                pageState.filter.value,
                                sortVal,
                                pageState.sortState.flip
                            ),
                            sortState: {
                                ...pageState.sortState,
                                param: sortVal,
                            },
                        });
                    }}
                    updateParentFlip={() => {
                        const flipVal = pageState.sortState.flip * -1;
                        setPageState({
                            ...pageState,
                            paginationArr: filterAndSortHelper(
                                pageState.cowHolder?.getCows(),
                                pageState.filter.value,
                                pageState.sortState.param,
                                flipVal
                            ),
                            sortState: {
                                ...pageState.sortState,
                                flip: flipVal,
                            },
                        });
                    }}
                />
                <div className="p-1">
                    <h2 className="my-0 mr-2">Search</h2>
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-32 border-2 border-gray-200 p-2 rounded "
                        onChange={(e) => {
                            setPageState({
                                ...pageState,
                                paginationArr: filterAndSortHelper(
                                    pageState.cowHolder
                                        ?.getCows()
                                        .filter((cow: indivCow) =>
                                            cow
                                                .getTag()
                                                .toUpperCase()
                                                .includes(
                                                    e.target.value.toUpperCase()
                                                )
                                        ),
                                    pageState.filter.value,
                                    pageState.sortState.param,
                                    pageState.sortState.flip
                                ),
                            });
                        }}
                    />
                </div>
            </div>

            <div className="mt-3">
                <PaginatedListView
                    DisplayItems={DisplayItems}
                    pageUpHandler={function (): void {
                        const pageNo = pageUp(
                            pageState.currentPage,
                            pageState.paginationArr
                        );
                        setPageState({ ...pageState, currentPage: pageNo });
                    }}
                    pageDownHandler={function (): void {
                        const pageNo = pageDown(pageState.currentPage);
                        setPageState({ ...pageState, currentPage: pageNo });
                    }}
                    getPageCount={function (): number {
                        return pageState.paginationArr
                            ? pageState.paginationArr.length
                            : 0;
                    }}
                    getCurrentPage={function (): number {
                        return pageState.currentPage + 1;
                    }}
                />
            </div>
        </div>
    );
}
