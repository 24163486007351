import { gql } from "@apollo/client";
import { StockMovement } from "../../../util/Types/StockMovementType";
import { createSmInput } from "../../../util/validators/ValidateStockMovement";

export const FETCH_ALL_STOCKITEMS_QUERY = gql`
    {
        getStockItems {
            id
            stockItemName
        }
    }
`;

export function GET_FETCH_SOH_QUERY(siID: string) {
    return gql`
    {
    getSOH(siID: ${siID})
    }
    `;
}
export function GET_CREATE_STOCKITEM_QUERY(siName: string) {
    return gql`
    mutation{
  createStockItem(stockItemName: "${siName}"){
      id
      stockItemName
  }
}
    `;
}

export function GET_DELETE_STOCKITEM_QUERY(siID: string) {
    return gql`
   mutation{
  deleteStockItem(stockItemID: "${siID}")
}
    `;
}

export function GET_FETCH_SM_QUERY(siID: string) {
    return gql`
        {
            getAllStockMovementsForSi(siID: "${siID}") {
                date
                id
                movementAmount
                note
                reason
                stockItem {
                    id
                    stockItemName
                }
            }
        }
    `;
}

export function GET_ADD_SM_QUERY({
    siID,
    note,
    date,
    mvAmt,
    reason,
}: createSmInput) {
    return gql`
        mutation {
            addStockMovement(
                siID: ${siID}
                mvAmt: ${mvAmt}
                ${note ? `note: "${note}"` : ""}
                reason: "${reason}"
                date: "${date}"
            ) {
                id
                date
                movementAmount
                note
                reason
                stockItem {
                    id
                    stockItemName
                }
            }
        }
    `;
}
export function GET_UPDATE_SM_QUERY({
    id,
    date,
    reason,
    movementAmount,
    stockItem,
    note,
}: StockMovement) {
    return gql`
        mutation {
            updateStockMovement(
                smID: "${id}"
                mvAmt: ${movementAmount}
                note: "${note}"
                reason: "${reason}"
                date: "${date}"
            ) {
                id
                date
                movementAmount
                note
                reason
                stockItem {
                    id
                    stockItemName
                }
            }
        }
    `;
}

export function GET_DELETE_SM_QUERY(smID: string) {
    return gql`
  mutation{
  deleteStockMovement(stockMvID: "${smID}")
}
    `;
}
