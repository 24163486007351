import React, { FunctionComponent, useContext } from "react";
import { AuthContext } from "../context/auth";
import { Redirect, Route } from "react-router-dom";

type PrivateRouteProps = {
    Comp: FunctionComponent;
    publicComponent?: JSX.Element;
    redirectPath: String;
};
// Componentnt to render either a public page, private page or redirect to another page
export default function PrivateRoute({
    Comp,
    publicComponent,
    redirectPath,
    ...rest
}: PrivateRouteProps | any) {
    const AuthorizationContext = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (AuthorizationContext.user) {
                    return <Comp />;
                } else {
                    return publicComponent ? (
                        publicComponent
                    ) : (
                        <Redirect to={`${redirectPath}`} />
                    );
                }
            }}
        />
    );
}
