import React from "react";
type ButtonProps = {
    content: string | JSX.Element;
    onClick(e: any): void;
    classNameAdd: string;
};
// Default button component.
// Takes a classNameAdd prop so that css properties can be added
export default function ButtonTeal({
    content,
    onClick,
    classNameAdd,
}: ButtonProps) {
    return (
        <button
            className={`bg-teal-400  hover:bg-teal-600 p-3 rounded ml-2 ${classNameAdd}`}
            onClick={onClick}
        >
            {content}
        </button>
    );
}
