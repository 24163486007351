import gql from "graphql-tag";
import { createRiInput } from "../../../util/validators/validateRecipeItem";

export const FETCH_ALL_RECIPES_QUERY = gql`
    {
        getRecipes {
            id
            items {
                id
                percentage
                stockItem {
                    id
                    stockItemName
                }
            }
            recipeName
        }
    }
`;
export function GET_CREATE_RECIPE_QUERY(recipeName: string) {
    return gql`
        mutation {
            createRecipe(recipeName: "${recipeName}") {
                id
                recipeName
            }
        }
    `;
}

export function GET_CREATE_RECIPE_ITEM_QUERY({
    percentage,
    recipeID,
    stockItemId,
}: createRiInput) {
    return gql`
        mutation {
            createRecipeItem(percentage: ${percentage}, recipeID: "${recipeID}", stockItemId: "${stockItemId}") {
                id
                percentage
                stockItem {
                    id
                    stockItemName
                }
                recipe {
                    id
                    recipeName
                }
            }
        }
    `;
}

export function GET_ADJUST_RECIPE_ITEM_QUERY(
    RecipeItemID: string,
    newPercentage: string
) {
    return gql`
        mutation {
            adjustRecipeItem(RecipeItemID: "${RecipeItemID}", newPercentage: ${newPercentage}) {
                id
                percentage
                stockItem {
                    id
                    stockItemName
                }
                recipe {
                    id
                    recipeName
                }
            }
        }
    `;
}

export function GET_DELETE_RECIPE_ITEM_QUERY(RiID: string) {
    return gql`
mutation{
   removeRecipeItem(RecipeItemID: "${RiID}")
}
    `;
}

export function GET_PROCESS_RECIPE_QUERY(RiID: string, amount: number) {
    return gql`
mutation{
  processRecipe(recipeID:"${RiID}",amount: ${amount})
}
    `;
}

export function GET_DELETE_RECIPE_QUERY(RID: string) {
    return gql`
mutation{
    deleteRecipe(recipeID:"${RID}")
}
    `;
}
