import simpleValidatorResponse from "./simpleValidatorResponse";
import ValidatorResponse from "../Types/ValidatorResponseInterface";
import { StockMovement } from "../Types/StockMovementType";

export type createSmInput = {
    siID: string;
    note?: string;
    date: string;
    mvAmt: number;
    reason: string;
};

export function validateCreateSmInput({
    siID,
    note,
    date,
    mvAmt,
    reason,
}: createSmInput): ValidatorResponse {
    let valid = true;
    let errors: string[] = [];

    if (!siID) {
        valid = false;
        errors.push("Invalid or no stockItem provided");
    }
    if (!date) {
        valid = false;
        errors.push("Invalid or no date provided");
    }
    if (!reason || reason.length < 1) {
        valid = false;
        errors.push("Invalid or no reason provided");
    }
    if (!mvAmt) {
        valid = false;
        errors.push("Invalid or no mvAmt provided");
    }

    if (!valid) {
        return new simpleValidatorResponse(valid, errors);
    }
    return new simpleValidatorResponse(valid);
}

export function validateUpdateSmInput({
    date,
    reason,
    movementAmount,
}: StockMovement): ValidatorResponse {
    let valid = true;
    let errors: string[] = [];

    if (!date) {
        valid = false;
        errors.push("Invalid or no date provided");
    }
    if (!reason || reason.length < 1) {
        valid = false;
        errors.push("Invalid or no reason provided");
    }
    if (!movementAmount) {
        valid = false;
        errors.push("Invalid or no mvAmt provided");
    }

    if (!valid) {
        return new simpleValidatorResponse(valid, errors);
    }
    return new simpleValidatorResponse(valid);
}
