import React from "react";
import TabNavigate from "../../components/TabNavigate/TabNavigate";
import RecipePage from "./Recipes/RecipePage";
import StockItemPage from "./StockItems/StockItemPage";

export default function StockPage() {
    let tabs = [
        {
            tabIndex: "STOCK_ITEM",
            buttonText: "StockItems",
            element: <StockItemPage />,
        },
        {
            tabIndex: "RECIPES",
            buttonText: "Recipes",
            element: <RecipePage />,
        },
    ];
    return (
        <div className="container mt-4">
            {/* <div className="flex bg-gray-300 p-3 w-full rounded">
            </div> */}
            <TabNavigate startTab={tabs[0]} tabArray={tabs} />
        </div>
    );
}
