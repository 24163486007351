import simpleValidatorResponse from "./simpleValidatorResponse";
import ValidatorResponse from "../Types/ValidatorResponseInterface";

export type createRiInput = {
    percentage: number | string;
    recipeID?: number | string;
    stockItemId?: number | string;
};

export function validateCreateRiInput({
    percentage,
    recipeID,
    stockItemId,
}: createRiInput): ValidatorResponse {
    let valid = true;
    let errors: string[] = [];

    if (!percentage) {
        valid = false;
        errors.push("Invalid or no percentage provided");
    }
    if (!recipeID) {
        valid = false;
        errors.push("Invalid or no recipeID provided");
    }
    if (!stockItemId) {
        valid = false;
        errors.push("Invalid or no stockItemId provided");
    }

    if (!valid) {
        return new simpleValidatorResponse(valid, errors);
    }
    return new simpleValidatorResponse(valid);
}
