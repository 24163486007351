import React, { useState } from "react";
import PaginatedListView from "../../../components/Pagination/PaginatedListView";
import {
    makePageArray,
    pageDown,
    pageUp,
} from "../../../util/helpers/paginationHelpers";
import { StockItem } from "../../../util/Types/StockItemType";
import SingleSiView from "./SingleSiView/SingleSiView";
import StockItemCard from "./StockItemCard";
type viewStockItemsProps = {
    stockItems: StockItem[];
    deleteCallBack: (id: String) => void;
};

type viewStockItemsPageState = {
    currentPage: number;
    paginationArr: any[][];
    isViewing: boolean;
    currentViewingSI?: StockItem;
};

export default function ViewStockItems({
    stockItems,
    deleteCallBack,
}: viewStockItemsProps) {
    const [pageState, setPageState] = useState<viewStockItemsPageState>({
        currentPage: 0,
        paginationArr: makePageArray(stockItems, 10),
        isViewing: false,
    });

    if (pageState.isViewing && pageState.currentViewingSI) {
        return (
            <SingleSiView
                stockItem={pageState.currentViewingSI}
                endViewIngCallBack={() => {
                    setPageState({
                        ...pageState,
                        isViewing: false,
                        currentViewingSI: undefined,
                    });
                }}
            />
        );
    }

    let DisplayItems = pageState.paginationArr[pageState.currentPage].map(
        (StockItem) => {
            return (
                <StockItemCard
                    stockItem={StockItem}
                    key={StockItem.stockItemName}
                    viewCallBack={(Si: StockItem) => {
                        setPageState({
                            ...pageState,
                            isViewing: true,
                            currentViewingSI: Si,
                        });
                    }}
                    deleteCallBack={(id) => {
                        const pageArray = [...pageState.paginationArr].map(
                            (subArr) => {
                                return subArr.filter((si) => si.id !== id);
                            }
                        );
                        deleteCallBack(id);
                        setPageState({
                            ...pageState,
                            paginationArr: pageArray,
                        });
                    }}
                />
            );
        }
    );
    return (
        <div className="p-3 bg-gray-300">
            <PaginatedListView
                DisplayItems={DisplayItems}
                pageUpHandler={function (): void {
                    const pageNo = pageUp(
                        pageState.currentPage,
                        pageState.paginationArr
                    );
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                pageDownHandler={function (): void {
                    const pageNo = pageDown(pageState.currentPage);
                    setPageState({ ...pageState, currentPage: pageNo });
                }}
                getPageCount={function (): number {
                    return pageState.paginationArr
                        ? pageState.paginationArr.length
                        : 0;
                }}
                getCurrentPage={function (): number {
                    return pageState.currentPage + 1;
                }}
            />
        </div>
    );
}
