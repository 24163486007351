
export function calculateDaysBetween(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const dif = d1 - d2;
    // console.log(dif / (60 * 60 * 24 * 1000));
    return dif / (60 * 60 * 24 * 1000);
};

export function isAfter(D1, D2) {
    return new Date(D1) > new Date(D2); // true if D1 is after
}
export function isBefore(D1, D2) {
    return new Date(D1) < new Date(D2); // true if D1 is before
}