import { client } from "../ApolloProvider"
import { gql } from '@apollo/client'

export default class feedLogClass {
    constructor(feedLogArr) {
        if (!feedLogArr) {
            this.logs = []
        } else {
            this.logs = [...feedLogArr]
        }
    }


    getLogs() {
        return this.logs;
    }
    addExistingLog(log) {
        this.logs.push(log)
    }
    addLog(date, groupId, feedtype, mass, note, updateCallback) {
        console.log(mass)
        const ADDQUERY = gql`
        mutation{
  addFeedLog(
      CreateFeedLogInput:{
          date:"${date}", 
          groupId:"${groupId}",
          feedType:"${feedtype}",
          mass:${parseInt(mass)},
          note:"${note}"
          }
          ){
   ${STDFIELDS}
  }
}`
        client.mutate({ mutation: ADDQUERY, }).then((data) => {
            console.log(data)
            updateCallback(data.data.addFeedLog)
            this.logs.push(data.data.addFeedLog)

        }).catch((err) => {
            console.log(err)
        })
    }
    updateFeedLog(flId, date, groupId, feedType, mass, note, updateHandler) {
        const UPDATEFEDFLOGQUERY = gql`
        mutation{
        updateFeedLog(flId:"${flId}", note:"${note}", date:"${date}", feedType:"${feedType}", groupId:"${groupId}", mass: ${mass}){
            ${STDFIELDS}
        }
        }
        `
        client.mutate({ mutation: UPDATEFEDFLOGQUERY }).then((data) => {
            console.log(data)
            this.logs.push(data.data.updateFeedLog)
            updateHandler(data.data.updateFeedLog)
        }).catch((err) => {
            console.log(err)
        })
    }
    deleteFeedLog(flId, deleteHandler) {
        const DELETEQUERY = gql`
        mutation{
deleteFeedLog(flId:"${flId}")
}
`
        client.mutate({ mutation: DELETEQUERY }).then((data) => {
            console.log(data)

            deleteHandler(flId)

        }).catch((err) => {
            console.log(err)
        })
    }
}
const STDFIELDS = `
id
date
mass
group{
  id
  name
}
feedType
note
`