import { gql } from "@apollo/client";


export const FETCH_ALL_DATA_QUERY = gql`
query {
getAllCows{
    id
tagNo
origin
groups{
  currentGroupID
  currentGroupName
  history{
    id
    groupId
    periodStart
    periodEnd
  }
}
weights{
  id
  date
  weight
  notes
}
medicineHistory{
    id
    medId
    date
    if_withdrawalEnd
    batchNo
    note
  }
}
getGroups{
  id
  name
}
getMeds{
  id
  medName
  withdrawalPeriod
  directions
  note
}
getFeeds{
  id
  feedName
  preferredWeightRange
  notes
  distribution{
    groupName
    groupId
    percentage
  }
  sessions{
     description
    amount
  }
  
}
}
`