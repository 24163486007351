// package imports
import { useQuery } from "@apollo/client";
import React, { useState } from "react";

//! component and helper imports
import CampRep from "./CampRep";
import FeedRep from "./FeedRep";
import { FETCH_COWS_QUERY } from "./PageQueries/ReportingPageGQLQueries";
import cowCollection from "../../util/cowCollectionClass";
import Loading from "../../components/Loading";
import { groupType } from "../../util/Types/GroupType";
import { FeedLogType } from "../../util/Types/FeedLogType";

// ! Type Definitions
type pageStateType = {
    cows?: cowCollection;
    groups?: Array<groupType>;
    feedLogs?: Array<FeedLogType>;
    fetched: boolean;
    repType: string;
};

export default function Reporting() {
    const [pageState, setPageState] = useState<pageStateType>({
        fetched: false,
        repType: "none",
    });

    const { error } = useQuery(FETCH_COWS_QUERY, {
        onCompleted: (data) => {
            console.log(data.getAllCows);
            setPageState({
                ...pageState,
                fetched: true,
                cows: new cowCollection(data.getAllCows),
                groups: data.getGroups,
                feedLogs: data.getFeedLogs,
            });
        },
    });
    if (error) return <>ERROR{console.log(error.message)}</>;
    if (!pageState.fetched) {
        return <Loading />;
    }
    if (!pageState.cows || !pageState.groups || !pageState.feedLogs) {
        return <>Error</>;
    }
    return (
        <div className="container ">
            <div className="mt-4 w-full p-5  flex justify-center items-center rounded bg-gray-100 text-black">
                <select
                    className="p-2 text-2xl"
                    name="currentGroupId"
                    onChange={(e) => {
                        setPageState({ ...pageState, repType: e.target.value });
                    }}
                    value={pageState.repType}
                >
                    <option className="p-2" value="campreport">
                        Camp Report
                    </option>
                    <option className="p-2" value="feedreport">
                        Feed Report
                    </option>
                    <option className="p-2" value="none">
                        -- Select Report --
                    </option>
                </select>
            </div>
            {pageState.repType === "campreport" ? (
                <CampRep cows={pageState.cows} groups={pageState.groups} />
            ) : null}
            {pageState.repType === "feedreport" ? (
                <FeedRep
                    groups={pageState.groups}
                    feedLogs={pageState.feedLogs}
                />
            ) : null}
        </div>
    );
}
