// package imports
import React from "react";

// component and helper imports
import CampCard from "./CampCard";
import campReportData from "../../util/campRep/campRepClass";
import repGenerator from "../../util/campRep/campRepGenerator";
import ButtonTeal from "../../components/InputComponents/ButtonTeal";
import cowCollection from "../../util/cowCollectionClass";
import { groupType } from "../../util/Types/GroupType";
import campRepGroup from "../../util/campRep/campRepGroupClass";

type campRepProps = {
    cows: cowCollection;
    groups: Array<groupType>;
};

export default function CampRep({ cows, groups }: campRepProps) {
    // const { cowState, groupState } = useContext(WorkingContext);

    // set up object for reporting
    // TODO: Add typing to camp rep class and refactor
    const repData = new campReportData(cows, [
        ...groups,
        { name: "unassigned", id: "unassigned" },
    ]);

    // pre sort groups in rep
    const sorted = [...repData.getRepData()].sort((a: any, b: any) => {
        if (a.group.name.toUpperCase() < b.group.name.toUpperCase()) {
            return -1;
        } else if (a.group.name.toUpperCase() > b.group.name.toUpperCase()) {
            return 1;
        }
        return 0;
    });

    return (
        <div className="bg-gray-100 mt-6 rounded text-black p-5 ">
            <h1>Group Weight Report</h1>
            <ButtonTeal
                content="Print"
                onClick={() => {
                    repGenerator(repData.getRepData());
                }}
                classNameAdd={""}
            />
            <div className="w-full p-3 bg-gray-300 flex flex-col sm:flex-row  items-center justify-evenly">
                <h1 className="m-0 p-0">
                    Total cows:
                    {cows.getCowCount().toLocaleString()}
                </h1>
                <h1 className="m-0 p-0">
                    Total weight:
                    {cows.getTotalWeight().toLocaleString()}
                </h1>
            </div>
            <hr />
            <div className="pb-4  h-vh70">
                <div className="overflow-y-scroll h-full">
                    {sorted.map((group: campRepGroup) => {
                        return <CampCard group={group} key={group.group.id} />;
                    })}
                </div>
            </div>
        </div>
    );
}
