import React, { useState } from "react";
import { client } from "../../../../ApolloProvider";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import ButtonTeal from "../../../../components/InputComponents/ButtonTeal";
import { RecipeItemType } from "../../../../util/Types/RecipeType";
import {
    GET_ADJUST_RECIPE_ITEM_QUERY,
    GET_DELETE_RECIPE_ITEM_QUERY,
} from "../RecipePageGQLQueries";

type PageProps = {
    recipeItem: RecipeItemType;
    updateRecipeItemCallback: (ri: RecipeItemType) => void;
    deleteCallBack: (id: string) => void;
};
type PageState = {
    percentage: number | string;
    editing: boolean;
};
export default function RecipeItemCard({
    recipeItem,
    updateRecipeItemCallback,
    deleteCallBack,
}: PageProps) {
    const [pageState, setPageState] = useState<PageState>({
        percentage: recipeItem.percentage,
        editing: false,
    });
    return (
        <div className="w-full p-6 bg-gray-100 rounded mb-2 flex justify-evenly items-center">
            <div className="  w-1/4">{recipeItem.stockItem.stockItemName}</div>
            <input
                type="number"
                className="p-3 m-2 rounded  w-1/4"
                max="100"
                min="0"
                placeholder="Percentage"
                value={pageState.percentage}
                onChange={(e: any) => {
                    console.log(parseFloat(e.target.value));
                    setPageState({ ...pageState, percentage: e.target.value });
                }}
                disabled={!pageState.editing}
            />
            <div className="flex items-center">
                {pageState.editing ? (
                    <ButtonTeal
                        content={"Save"}
                        onClick={function (e: any): void {
                            client
                                .mutate({
                                    mutation: GET_ADJUST_RECIPE_ITEM_QUERY(
                                        recipeItem.id,
                                        `${pageState.percentage}`
                                    ),
                                })
                                .then((data) => {
                                    console.log(data);
                                    alert("Success");
                                    updateRecipeItemCallback(
                                        data.data.adjustRecipeItem
                                    );
                                    setPageState({
                                        ...pageState,
                                        editing: false,
                                    });
                                });
                        }}
                        classNameAdd={""}
                    />
                ) : (
                    <ButtonTeal
                        content={"Edit"}
                        onClick={function (e: any): void {
                            setPageState({ ...pageState, editing: true });
                        }}
                        classNameAdd={"mr-2"}
                    />
                )}
                <ConfirmDelete
                    deleteFunc={() => {
                        client
                            .mutate({
                                mutation: GET_DELETE_RECIPE_ITEM_QUERY(
                                    recipeItem.id
                                ),
                            })
                            .then((data) => {
                                console.log(data);
                                deleteCallBack(recipeItem.id);
                            });
                    }}
                />
            </div>
        </div>
    );
}
