import React from "react";
import { groupType } from "../../util/Types/GroupType";

type FilterMenuItemProps = {
    groups: Array<groupType>;
    updateParent(filterVal: string): void;
};
export default function FilterMenuItem({
    groups,
    updateParent,
}: FilterMenuItemProps) {
    return (
        <div className=" p-1">
            <h2 className="my-0 mr-2">Filter</h2>
            <select
                className="w-32 border-2 border-gray-200 p-2 rounded"
                onChange={(e) => {
                    updateParent(e.target.value || "");
                }}
            >
                <option value="">all groups</option>
                <option value="unassigned">unassigned</option>
                {groups.map((group) => {
                    return (
                        <option key={group.id} value={group.id}>
                            {group.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}
